@custom-media --small (width < 1024px);

.banner {
  background-color: #f4bb00;
  padding: 0.25rem 1rem;
  margin: 72px 0 -72px;
  width: 100%;

  @media (--small) {
    padding: 0.25rem;
  }

  & .banner-wrapper {
    align-items: center;
    display: grid;
    grid-column-gap: 1rem;
    grid-template-areas: "empty content close";
    grid-template-columns: 1rem 1fr 1rem;
    max-width: 1600px;
    width: 100%;

    @media (--small) {
      align-items: flex-start;
      grid-template-areas: "content close";
      grid-template-columns: 1fr 1.5rem;
    }
  }

  & .banner-content {
    align-items: center;
    display: flex;
    font-size: 0.65rem;
    font-weight: 700;
    grid-area: content;
    justify-content: center;
    text-transform: uppercase;

    @media (--small) {
      align-items: flex-start;
      flex-direction: column;
    }

    & .button {
      flex-shrink: 0;
      margin-left: 1rem;
      white-space: nowrap;

      @media (--small) {
        margin-left: 0;
        margin-top: 0.25rem;
      }
    }
  }

  & .banner-close {
    align-items: center;
    cursor: pointer;
    display: flex;
    grid-area: close;
    height: 100%;
    justify-content: center;
    width: 100%;

    @media (--small) {
      height: 1.5rem;
    }

    & > svg {
      fill: #ececec;
      height: 0.75rem;
      width: 0.75rem;
    }

    &:hover svg {
      fill: #fff;
    }
  }
}
