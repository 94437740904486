.button {
  border-radius: 50px;
  box-shadow: var(--shadow-2);
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  letter-spacing: 0.025em;
  min-width: 150px;
  padding: 0.75rem 1.5rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: box-shadow 0.15s ease;
  user-select: none;

  &:hover {
    box-shadow: var(--shadow-3);
  }

  &:active {
    box-shadow: var(--shadow-2);
  }

  &.small {
    font-size: 0.65rem;
    padding: 0.45rem 1rem;
  }

  /* Primary button */
  &.button_primary {
    background-color: #f4bb00;
    color: #0c042e;
  }

  /* Secondary button */
  &.button_secondary {
    background-color: #fff;
    border: 2px solid #858196;
    color: #858196;
  }

  /* Danger button */
  &.button_danger {
    border: 2px solid #fe0000;
    color: #fe0000;
  }

  /* Friends button */
  &.button_friends {
    background-color: #4edfab;
  }
}

.ghost_button {
  color: #0c042e;
  cursor: pointer;
  text-decoration: underline;
}
