.footer {
  position: relative;
  width: 100%;
  z-index: 1;

  & .footer-main {
    background-color: #0c042e;
    padding: 3.375rem 1rem 1rem 1rem;
  }

  & .footer-main-container {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    max-width: 1080px;
  }

  & .footer-logo {
    height: 3.375rem;
    flex-grow: 1;
    margin: 0 1rem 2.25rem 0;
  }

  & .footer-nav {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  & .footer-nav-column {
    margin: 0 1rem 2.25rem 0;
  }

  & .footer-nav-column-heading {
    color: #858196;
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }

  & .footer-nav-column-list {
    & li {
      color: #fff;
      font-weight: 500;

      & + li {
        margin-top: 0.667rem;
      }

      & a {
        transition: opacity 0.1s ease;

        &:hover {
          opacity: 0.67;
        }
      }
    }
  }

  & .footer-copyright {
    background-color: #07021a;
    color: #fff;
    font-size: 0.75rem;
    padding: 1.5rem 1rem;
    text-align: center;
  }
}
