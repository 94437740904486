.policy {
  & .policy-content {
    min-height: 100vh;
    padding: calc(var(--header-height) + 3.375rem) 1.5rem 5.063rem;
    position: relative;

    &::after {
      background: url("../../assets/images/auth-bg.svg") bottom left / cover;
      background-attachment: fixed;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }

  & .policy-content-container {
    margin-top: 2.25rem;

    & > * + * {
      margin-top: 1.5rem;
    }
  }
}
