@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap");

:root {
  /* Palette */
  --level-one: #c40018;
  --level-two: #f15a24;
  --level-three: #f4bb00;
  --level-four: #03c47b;
  --level-five: #29abe2;

  /* Sizes */
  --header-height: 72px;

  /* Shadows */
  --shadow-1: rgba(50, 50, 93, 0.09) 0 2px 5px 0,
    rgba(0, 0, 0, 0.07) 0 1px 2px 0;
  --shadow-2: rgba(50, 50, 93, 0.09) 0 4px 6px 0,
    rgba(0, 0, 0, 0.08) 0 1px 3px 0;
  --shadow-3: rgba(0, 0, 0, 0.07) 0 1px 5px 0, rgba(0, 0, 0, 0.1) 0 7px 17px 0;
  --shadow-4: rgba(50, 50, 93, 0.1) 0 15px 35px 0,
    rgba(0, 0, 0, 0.07) 0 5px 15px 0;
  --shadow-5: rgba(50, 50, 93, 0.15) 0 15px 35px 0,
    rgba(0, 0, 0, 0.1) 0 5px 15px 0;

  /* Z-indexes */
  --z-header: 10;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  color: #0c042e;
  font: 18px/1.5 "Montserrat", sans-serif;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

body {
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4 {
  font-weight: inherit;
  line-height: 1.25;
  margin: 0;
}

h1 {
  font-size: 2.369rem;
  font-weight: 700;
  text-transform: uppercase;
}

h2 {
  font-size: 1.333rem;
  font-weight: 700;
  text-transform: uppercase;
}

h3 {
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

h4 {
  font-weight: 700;
}

sub {
  color: #8790a2;
  display: inline-block;
  font-size: 0.75rem;
  vertical-align: baseline;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

figure {
  margin: 0;
}

img {
  height: 100%;
  width: auto;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button {
  border: none;
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  outline: none;
  overflow: visible;
  width: auto;
  -webkit-appearance: none;
}

fieldset {
  border: none;
  padding: 0;
}
