@custom-media --small (width <= 768px);

.battery {
  /* Vertically-oriented battery variables */
  --battery-height: 75vh;
  --battery-border-width: 18px;
  --battery-positive-height: 52px;

  /* Horizontally-oriented battery variables */
  @media (--small) {
    --battery-border-width: 8px;
    --battery-height: 104px;
    --battery-positive-width: 24px;
  }

  display: flex;
  flex-direction: column;
  height: var(--battery-height);
  min-height: 680px;
  width: 212px;

  @media (--small) {
    height: auto;
    min-height: 0;
    width: 100%;
  }

  & .battery-indicator {
    background-color: #0c042e;
    border-radius: 9px 9px 0 0;
    color: #fff;
    margin: 0 auto 2.25rem;
    width: 160px;
    padding: 0.667rem;
    position: relative;
    text-align: center;
    z-index: 5;

    @media (--small) {
      margin-bottom: calc(1rem - 4px);
      width: 128px;
    }

    &::after {
      border-left: 80px solid transparent;
      border-right: 80px solid transparent;
      border-top: 1rem solid #0c042e;
      content: "";
      height: 0;
      left: 0;
      position: absolute;
      top: 100%;
      width: 0;

      @media (--small) {
        border-left-width: 64px;
        border-right-width: 64px;
      }
    }
  }

  & .battery-indicator-percentage {
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 1;

    @media (--small) {
      font-size: 1.5rem;
    }
  }

  & .battery-indicator-level {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    margin-top: 0.296rem;
    text-transform: uppercase;

    @media (--small) {
      font-size: 0.75rem;
    }
  }

  & .battery-shape {
    background-color: #fff;
    border: var(--battery-border-width) solid #bdccd4;
    border-radius: calc(var(--battery-border-width) * 2);
    height: 100%;
    padding: var(--battery-border-width);
    position: relative;
    margin-top: calc(
      var(--battery-positive-height) - var(--battery-border-width)
    ); /* Adjust position due to the positive terminal height */

    @media (--small) {
      height: var(--battery-height);
      margin-top: 0;
      top: 0;
      width: calc(
        100% - var(--battery-positive-width) + var(--battery-border-width)
      );
    }

    /* Battery positive terminal */
    &::after {
      background-color: #fff;
      border-left: var(--battery-border-width) solid #bdccd4;
      border-right: var(--battery-border-width) solid #bdccd4;
      border-top: var(--battery-border-width) solid #bdccd4;
      border-radius: 24px 24px 0 0;
      bottom: 100%;
      content: "";
      height: var(--battery-positive-height);
      left: 50%;
      position: absolute;
      transform: translate(-50%, 0);
      width: 86px;

      @media (--small) {
        border-left: none;
        border-bottom: var(--battery-border-width) solid #bdccd4;
        border-radius: 0 12px 12px 0;
        left: 100%;
        top: 50%;
        transform: translate(0, -50%);
        width: var(--battery-positive-width);
      }
    }

    /* Battery positive terminal's border inset */
    &::before {
      border-bottom: var(--battery-border-width) solid #fff;
      border-left: calc(var(--battery-border-width) * 1.333) solid transparent;
      border-right: calc(var(--battery-border-width) * 1.333) solid transparent;
      bottom: 100%;
      content: "";
      height: 0;
      left: 50%;
      position: absolute;
      transform: translate(-50%, 0);
      width: 74px;
      z-index: 1;

      @media (--small) {
        border-left: var(--battery-border-width) solid #fff;
        border-bottom: calc(var(--battery-border-width) * 1.333) solid
          transparent;
        border-top: calc(var(--battery-border-width) * 1.333) solid transparent;
        height: var(--battery-positive-height);
        left: 100%;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }

  & .battery-marks {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    width: 100%;

    @media (--small) {
      flex-direction: row-reverse;
    }
  }

  & .battery-marks-mark {
    background-color: #dde4e8;
    border-radius: calc(var(--battery-border-width) / 2);
    flex: 1;
    position: relative;
    width: 100%;

    &::after {
      animation: vertically_battery_level 1s linear forwards;
      border-radius: calc(var(--battery-border-width) / 2);
      content: "";
      height: 100%;
      position: absolute;
      transform: scale3d(1, 0, 1);
      transform-origin: bottom;
      width: 100%;

      @media (--small) {
        animation-name: horizontally_battery_level;
        transform: scale3d(0, 1, 1);
        transform-origin: left;
      }
    }

    &.level_one::after {
      animation-delay: 0s;
      animation-timing-function: ease-in;
      background-color: var(--level-one);
    }

    &.level_two::after {
      animation-delay: 1s;
      background-color: var(--level-two);
    }

    &.level_three::after {
      animation-delay: 2s;
      background-color: var(--level-three);
    }

    &.level_four::after {
      animation-delay: 3s;
      background-color: var(--level-four);
    }

    &.level_five::after {
      animation-delay: 4s;
      background-color: var(--level-five);
    }

    & + .battery-marks-mark {
      animation-delay: 5s;
      margin-top: var(--battery-border-width);

      @media (--small) {
        margin-top: 0;
        margin-right: var(--battery-border-width);
      }
    }
  }

  & .battery-marks-mark > .reward_icon {
    background-color: #8790a2;
    height: 71px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
    width: 71px;
    z-index: 2;

    @media (--small) {
      height: 34px;
      width: 34px;
    }

    &[class*="level_"] {
      background-color: #8790a2;
      transition: none;
    }

    &.is_reached.level_one {
      animation: 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s forwards
        enable_icon_level_one;
    }

    &.is_reached.level_two {
      animation: 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s forwards
        enable_icon_level_two;
    }

    &.is_reached.level_three {
      animation: 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 2s forwards
        enable_icon_level_three;
    }

    &.is_reached.level_four {
      animation: 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 3s forwards
        enable_icon_level_four;
    }

    &.is_reached.level_five {
      animation: 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 4s forwards
        enable_icon_level_five;
    }

    & .reward_icon-icon {
      height: 47px;
      width: 47px;

      @media (--small) {
        height: 23px;
        width: 23px;
      }
    }
  }
}

@keyframes vertically_battery_level {
  from {
    transform: scale3d(1, 0, 1);
  }
  to {
    transform: scale3d(1, var(--battery-level), 1);
  }
}

@keyframes horizontally_battery_level {
  from {
    transform: scale3d(0, 1, 1);
  }
  to {
    transform: scale3d(var(--battery-level), 1, 1);
  }
}

@keyframes enable_icon_level_one {
  0% {
    background-color: #8790a2;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
  25% {
    background-color: #8790a2;
    transform: translate(-50%, -50%) scale3d(1.25, 1.25, 1.25);
  }
  50% {
    background-color: var(--level-one);
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
  100% {
    background-color: var(--level-one);
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
}

@keyframes enable_icon_level_two {
  0% {
    background-color: #8790a2;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
  25% {
    background-color: #8790a2;
    transform: translate(-50%, -50%) scale3d(1.25, 1.25, 1.25);
  }
  50% {
    background-color: var(--level-two);
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
  100% {
    background-color: var(--level-two);
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
}

@keyframes enable_icon_level_three {
  0% {
    background-color: #8790a2;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
  25% {
    background-color: #8790a2;
    transform: translate(-50%, -50%) scale3d(1.25, 1.25, 1.25);
  }
  50% {
    background-color: var(--level-three);
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
  100% {
    background-color: var(--level-three);
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
}

@keyframes enable_icon_level_four {
  0% {
    background-color: #8790a2;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
  25% {
    background-color: #8790a2;
    transform: translate(-50%, -50%) scale3d(1.25, 1.25, 1.25);
  }
  50% {
    background-color: var(--level-four);
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
  100% {
    background-color: var(--level-four);
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
}

@keyframes enable_icon_level_five {
  0% {
    background-color: #8790a2;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
  25% {
    background-color: #8790a2;
    transform: translate(-50%, -50%) scale3d(1.25, 1.25, 1.25);
  }
  50% {
    background-color: var(--level-five);
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
  100% {
    background-color: var(--level-five);
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
}
