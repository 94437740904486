@custom-media --small (width <= 640px);

.modal {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;

  @media (--small) {
    padding: 0;
  }

  & .modal-container {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: var(--shadow-4);
    max-width: 768px;
    width: 100%;
  }

  &.success .modal-container {
    border: 3px solid #00b92b;
  }

  &.alert .modal-container {
    border: 3px solid #fe0000;
  }

  & .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2.25rem;
    text-align: center;

    @media (--small) {
      padding: 2.25rem 1rem;
    }

    & > * + * {
      margin-top: 1rem;
    }
  }

  & .modal-content-actions {
    display: flex;
    justify-content: center;
    margin-top: 2.25rem;

    & > * {
      @media (--small) {
        display: inline-block;
        font-size: 0.75rem;
        padding: 0.444rem 1rem;
      }
    }

    & > * + * {
      margin-left: 1.5rem;

      @media (--small) {
        margin-left: 1rem;
      }
    }
  }
}
