@custom-media --medium (width <= 1440px);
@custom-media --small (width < 1240px);
@custom-media --xsmall (width < 960px);

.project_ad {
  padding: 0 1rem;
  position: relative;
  width: 100%;
  background-color: #fff;

  @media (--small) {
    background: #fff;
  }

  & .project_ad-container {
    align-items: center;
    display: flex;
    padding: 5.063rem 0;
    margin: auto;
    max-width: 1080px;
    position: relative;
    width: 100%;
    z-index: 2;

    @media (--xsmall) {
      flex-direction: column;
      max-width: 768px;
      padding: 3.375rem 0;
    }

    & > * {
      flex: 1;
    }
  }

  &.dashboard .project_ad-container {
    max-width: 1280px;
  }

  & .project_ad-content {
    & > h2 {
      margin-top: 1rem;
    }

    & > p + p {
      margin-top: 1rem;
    }

    & > p:first-of-type,
    & > .button {
      margin-top: 1.5rem;
    }
  }

  & .project_ad-image {
    margin-left: 2.25rem;

    @media (--xsmall) {
      margin-left: 0;
      margin-top: 1.5rem;
    }

    & > img {
      box-shadow: var(--shadow-3);
      height: auto;
      width: 100%;
    }
  }

  /* Invite your friends styles */
  &.friends {
    background-color: #e5f3f9;

    & h2 {
      text-transform: none;
    }

    & .project_ad-image {
      display: flex;
      justify-content: center;
    }

    & img {
      box-shadow: none;
      max-width: 440px;
    }
  }
}
