@custom-media --small (width < 768px);

.landing_rewards {
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px solid #05ce78;
  border-radius: 20px;
  color: #0c042e;
  max-width: 810px;
  padding: 1.5rem;
  width: 100%;

  @media (--small) {
    padding: 0.667rem;
  }

  & .landing_rewards-list-item {
    align-items: center;
    display: flex;

    @media (--small) {
      align-items: baseline;
    }

    & + .landing_rewards-list-item {
      margin-top: 1rem;

      @media (--small) {
        margin-top: 0.667rem;
      }
    }
  }

  & .reward_icon {
    height: 48px;
    width: 48px;

    @media (--small) {
      height: 32px;
      width: 32px;
    }

    & .reward_icon-icon {
      height: 32px;
      width: 32px;

      @media (--small) {
        height: 22px;
        width: 22px;
      }
    }
  }

  & .landing_rewards-list-item-info {
    align-items: center;
    display: flex;
    margin-left: 1rem;
    width: 100%;

    @media (--small) {
      align-items: flex-start;
      flex-direction: column;
      margin-left: 0.444rem;
      position: relative;
      top: -4px;
    }
  }

  & .landing_rewards-list-item-info-name {
    line-height: 1.25;
    margin-right: auto;
  }

  & .landing_rewards-list-item.not_gained .landing_rewards-list-item-info-name {
    color: #8790a2;
  }

  & .landing_rewards-list-item-info-degrees {
    border-radius: 7px;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 600;
    margin-left: 1rem;
    padding: 0.296rem 0.667rem;
    white-space: nowrap;

    @media (--small) {
      line-height: 1;
      margin-left: 0;
      margin-top: 0.296rem;
    }

    &.level_one {
      background-color: var(--level-one);
    }

    &.level_two {
      background-color: var(--level-two);
    }

    &.level_three {
      background-color: var(--level-three);
    }

    &.level_four {
      background-color: var(--level-four);
    }

    &.level_five {
      background-color: var(--level-five);
    }
  }
}
