.pledge_card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: var(--shadow-2);
  color: #0c042e;
  cursor: pointer;
  display: flex;
  margin: 0.5rem;
  min-height: 364px;
  overflow: hidden;
  transform: scale3d(1, 1, 1);
  transition: 0.2s ease;
  width: 298px;

  &:hover {
    transform: scale3d(1.034, 1.034, 1);
  }

  & .pledge_card-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  & .pledge_card-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 182px;
  }

  & .pledge_card-content {
    border-top: 1px solid #e8e8e8;
    padding: 1rem;
  }

  & .pledge_card-content-description {
    font-size: 0.75rem;
    margin-top: 0.667rem;
  }

  & .pledge_card-info {
    align-items: flex-end;
    display: flex;
    flex: 1 0 auto;
    justify-content: space-between;
    padding: 0 1rem 1rem;
  }

  & .pledge_card-info-boost {
    align-items: center;
    background-color: #0c042e;
    border-radius: 7px;
    color: #fff;
    display: flex;
    font-size: 0.667rem;
    font-weight: 700;
    margin-top: 0.296rem;
    padding: 0.296rem 0.667rem;
    text-transform: uppercase;
  }

  & .boost-battery {
    height: 0.75rem;
    margin-left: 6px;
  }

  & .pledge_card-info-price {
    color: #a7adb3;
    font-size: 0.75rem;
  }
}
