@custom-media --medium (width <= 920px);
@custom-media --small (width <= 640px);
@custom-media --xsmall (width <= 460px);

.settings_card {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: var(--shadow-2);
  padding: 1rem 1.5rem 1.5rem;

  & .settings_card-header {
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.444rem;

    @media (--small) {
      align-items: flex-start;
      flex-direction: column;
    }

    & .button {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.444rem 1rem;

      @media (--small) {
        margin-top: 1rem;
      }
    }
  }

  & .settings_card-form {
    padding-top: 1rem;

    & .button:not(:first-child) {
      margin-top: 1.5rem;
    }
  }

  & .settings_card-form-container {
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    padding-bottom: 1.5rem;

    @media (--medium) {
      flex-direction: column;
    }
  }

  & .settings_card-form-fieldset {
    flex: 1;

    &:first-child {
      @media (--xsmall) {
        align-self: center;
      }
    }

    & + .settings_card-form-fieldset {
      flex: 10;
      margin-left: 2.25rem;

      @media (--medium) {
        margin-left: 0;
        margin-top: 1.5rem;
      }
    }
  }

  & .settings_card-form-avatar .file_uploader {
    margin-top: 0.75rem;
  }

  & .settings_card-form-avatar-image {
    border: 10px solid #0c042e;
    border-radius: 50%;
    height: 165px;
    overflow: hidden;
    width: 165px;
  }

  & .settings_card-form-fieldset-row {
    align-items: flex-end;
    display: flex;

    @media (--small) {
      align-items: flex-start;
      flex-direction: column;
    }

    & + .settings_card-form-fieldset-row {
      margin-top: 1rem;
    }

    & > * {
      flex: 1;
    }

    & > * + * {
      margin-left: 1rem;

      @media (--small) {
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }

  & .settings_card-form-fieldset-row-empty {
    @media (--small) {
      display: none;
    }
  }
}
