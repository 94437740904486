@custom-media --medium (width <= 960px);
@custom-media --small (width <= 768px);

.news {
  & .button {
    @media (--small) {
      font-size: 0.85rem;
    }
  }

  & .news-splash {
    background-position: center;
    background-size: cover;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: calc(var(--header-height) + 3.375rem) 1.5rem 3.375rem;
    height: 80vh;

    & > * + * {
      margin-top: 2.25rem;

      @media (--small) {
        margin-top: 1.5rem;
      }
    }
  }

  & .news-splash-logo {
    max-width: 768px;
    width: 100%;

    & > img {
      height: auto;
      width: 100%;
    }
  }

  & .news-project {
    background-color: #edf0f2;
  }

  & .news-project-container {
    display: flex;
    margin: auto;
    max-width: 1280px;
    padding: 5.053rem 1.5rem;
    width: 100%;

    @media (--medium) {
      flex-direction: column;
    }

    @media (--small) {
      padding: 3.375rem 1rem;
    }

    & > * {
      flex: 1;
    }
  }

  & .news-project-content {
    & > h1 {
      margin-bottom: 1.5rem;
    }
    & > p + p {
      margin-top: 0.444rem;
    }
  }

  & .news-project-image {
    margin-left: 2.25rem;

    @media (--medium) {
      margin-left: 0;
      margin-top: 1.5rem;
    }

    & > img {
      height: auto;
      width: 100%;
    }
  }

  & .news-pledges-container {
    margin: auto;
    max-width: 1280px;
    padding: 5.053rem 1.5rem;
    width: 100%;
    text-align: center;

    @media (--small) {
      padding: 3.375rem 1rem;
    }

    & > h2 {
      font-size: 2.369rem;
      margin-bottom: 0.444rem;
    }
  }

  & .news-pledges-list {
    margin: 5.053rem auto;
    max-width: 960px;
    width: 100%;
    text-align: left;

    @media (--small) {
      margin: 3.375rem auto;
    }

    & > * + * {
      margin-top: 3.375rem;

      @media (--small) {
        /* margin-top: 2.25rem; */
      }
    }
  }

  & .news-pledges-list-pledge {
    display: flex;

    @media (--small) {
      flex-direction: column;
    }

    &:nth-child(even) .news-pledges-list-pledge-description {
      order: 1;
      margin-left: 2.25rem;

      @media (--small) {
        margin-left: 0;
      }
    }

    &:nth-child(odd) .news-pledges-list-pledge-image {
      margin-left: 2.25rem;

      @media (--small) {
        margin-left: 0;
      }
    }

    & > * {
      flex: 1;
    }
  }

  & .news-pledges-list-pledge-description {
    @media (--small) {
      order: 2;
    }

    & > h3 {
      font-size: 1.5rem;
    }

    & > h4 {
      text-transform: uppercase;
    }

    & > * + * {
      margin-top: 0.444rem;
    }
  }

  & .news-pledges-list-pledge-description-boost {
    align-items: center;
    background-color: #0c042e;
    border-radius: 7px;
    color: #fff;
    display: flex;
    font-size: 0.667rem;
    font-weight: 700;
    margin-top: 0.296rem;
    padding: 0.296rem 0.667rem;
    text-transform: uppercase;
    width: max-content;
    white-space: nowrap;
  }

  & .boost-battery {
    height: 0.75rem;
    margin-left: 6px;
  }

  & .news-pledges-list-pledge-image {
    @media (--small) {
      margin-left: 0;
      margin-bottom: 1rem;
    }

    & > img {
      height: auto;
      width: 100%;
    }
  }
}
