@custom-media --small (width < 768px);

.sidenav {
  & .sidenav-navs {
    height: 100vh;
    overflow-y: auto;
    padding-top: 2.369rem;
    position: sticky;
    top: 0;

    @media (--small) {
      height: auto;
      padding-bottom: 1rem;
    }
  }

  & .sidenav-navs-nav {
    &.is_expanded {
      & .sidenav-navs-nav-button-chevron {
        transform: rotate(90deg);
      }

      & .sidenav-navs-nav-list {
        height: auto;
      }
    }

    & + .sidenav-navs-nav {
      margin-top: 1rem;
    }
  }

  & .sidenav-navs-nav-button {
    align-items: baseline;
    display: flex;
  }

  & .sidenav-navs-nav-button-chevron {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 1rem;

    & > svg {
      height: 0.75rem;

      & path {
        fill: #0c042e;
      }
    }
  }

  & .ghost_button {
    background-color: transparent;
    font-weight: 700;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
  }

  & .sidenav-navs-nav-list {
    height: 0;
    margin-top: 0.563rem;
    overflow-y: hidden;
    padding-left: 1.333rem;
  }

  & .sidenav-navs-nav-list-item {
    color: #858196;

    &:hover {
      text-decoration: underline;
    }

    & + .sidenav-navs-nav-list-item {
      margin-top: 0.563rem;
    }
  }
}
