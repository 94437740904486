.text_input {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 400px;
  position: relative;
  width: 100%;

  & .text_input-label {
    font-size: 0.667rem;
    font-weight: 600;
  }

  & .text_input-helper {
    color: #8ca4b5;
    font-size: 0.667rem;
  }

  & .text_input-input {
    appearance: none;
    background-color: #f9fbfc;
    color: #0c042e;
    display: block;
    border: 1px solid #8ca4b5;
    border-radius: 50px;
    font-size: 1rem;
    margin-top: 4px;
    min-width: 200px;
    outline: none;
    padding: 0.667rem 1rem;
    transition: border-color 0.1s ease;
    width: 100%;

    &[readonly] {
      border-color: #ededed;

      &:focus {
        border-color: #ededed;
      }
    }

    &.text_input-input_validation {
      border-color: #fe0000;
    }

    &::placeholder {
      color: #8ca4b5;
    }

    &:focus {
      border-color: #0c042e;
    }
  }

  & .text_input-forgot_link {
    color: #858196;
    font-size: 0.667rem;
    position: absolute;
    right: 1rem;
    text-decoration: underline;
    top: 0;
  }

  & .text_input-validation {
    color: #fe0000;
    font-size: 0.667rem;
    padding-top: 2px;
    position: relative;

    &::before {
      content: "*";
    }
  }
}
