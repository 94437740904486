@custom-media --large (width <= 1440px);
@custom-media --medium (width <= 1200px);
@custom-media --small (width <= 1024px);
@custom-media --xsmall (width < 768px);

.friends_dashboard {
  min-height: 100vh;
  padding: calc(var(--header-height) + 3.375rem) 1.5rem 5.063rem;
  position: relative;

  @media (--small) {
    padding: calc(var(--header-height) + 2.25rem) 1.5rem 3.375rem;
  }

  @media (--xsmall) {
    padding: calc(var(--header-height) + 2.25rem) 1rem 3.375rem;
  }

  &::after {
    background: url("../../assets/images/auth-bg.svg") bottom left / cover;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  & .friends_dashboard-container {
    margin: auto;
    max-width: 1440px;
    width: 100%;

    & h1 {
      margin-left: 1.777rem;

      @media (--xsmall) {
        margin-left: 0;
      }
    }
  }

  & .friends_dashboard-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3.157rem;
    margin-top: 1.777rem;

    @media (--large) {
      grid-column-gap: 2.369rem;
    }

    @media (--medium) {
      grid-template-columns: 3fr 2fr;
    }

    @media (--small) {
      grid-template-columns: 1fr;
    }
  }

  & .friends_dashboard-content-boards {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 1rem;
    padding: 1.777rem;

    @media (--xsmall) {
      background: none;
      padding: 0;
    }

    & > * + * {
      margin-top: 1.777rem;
    }
  }

  & .friends_dashboard-content-illustration {
    align-items: center;
    display: flex;

    @media (--small) {
      display: none;
    }

    & > img {
      height: auto;
      width: 100%;
    }
  }
}
