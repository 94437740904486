.file_uploader {
  display: inline-block;
  position: relative;

  & .file_uploader-input {
    color: transparent;
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    opacity: 0;
    outline: none;
    top: 0;
    width: 100%;
    z-index: 1;

    &::-webkit-file-upload-button {
      visibility: hidden;
    }
  }

  & .file_uploader-ui {
    background-color: #fff;
    border: 2px solid #858196;
    border-radius: 50px;
    box-shadow: var(--shadow-2);
    color: #858196;
    content: "Upload file";
    cursor: pointer;
    display: inline-block;
    font: 0.75rem/1.15 "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: 0.025em;
    min-width: 150px;
    outline: none;
    padding: 0.444rem 1rem;
    text-align: center;
    text-transform: uppercase;
    transition: box-shadow 0.15s ease;
    user-select: none;
    white-space: nowrap;
    -webkit-user-select: none;
  }

  &:hover .file_uploader-ui {
    box-shadow: var(--shadow-3);
  }

  &:active .file_uploader-ui {
    box-shadow: var(--shadow-2);
  }
}
