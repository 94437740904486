@custom-media --medium (width <= 1440px);
@custom-media --small (width < 768px);

.friends_form {
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: var(--shadow-2);
  padding: 3.157rem;

  @media (--medium) {
    padding: 2.369rem 1.777rem;
  }

  @media (--small) {
    padding: 1.333rem 1rem;
  }

  & .friends_form-description {
    & > p {
      margin-top: 1rem;
    }

    & > p + p {
      margin-top: 0.422rem;
    }
  }

  & .friends_form-form {
    margin-top: 2.369rem;

    & .button {
      margin-top: 1rem;
    }
  }
}
