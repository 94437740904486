.info_card {
  align-items: center;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: var(--shadow-3);
  display: flex;
  flex-direction: column;
  padding: 2.25rem;
  max-width: 340px;
  text-align: center;
  width: 100%;

  & > * + * {
    margin-top: 1.5rem;
  }

  & .info_card-heading {
    font-weight: 700;
    text-transform: uppercase;
  }

  & .info_card-icon {
    align-items: center;
    background-color: #f4bb00;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    height: 80px;
    width: 80px;

    & img {
      width: 53px;
      height: 53px;
    }
  }

  & .info_card-description {
    font-size: 1rem;
  }
}
