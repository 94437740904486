.reward_card {
  align-items: center;
  background-color: #fff;
  border: 5px solid #8790a2;
  border-radius: 25px;
  box-shadow: var(--shadow-3);
  height: 240px;
  overflow: hidden;
  position: relative;
  max-width: 240px;
  width: 100%;

  &::before {
    background: linear-gradient(#fff 0%, #fff 50%, #8790a2 100%);
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.67;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  /* UI variants */
  &.is_reached {
    &.level_one {
      border-color: var(--level-one);

      &::before {
        background: linear-gradient(#fff 0%, #fff 50%, var(--level-one) 100%);
      }
    }

    &.level_two {
      border-color: var(--level-two);

      &::before {
        background: linear-gradient(#fff 0%, #fff 50%, var(--level-two) 100%);
      }
    }

    &.level_three {
      border-color: var(--level-three);

      &::before {
        background: linear-gradient(#fff 0%, #fff 50%, var(--level-three) 100%);
      }
    }

    &.level_four {
      border-color: var(--level-four);

      &::before {
        background: linear-gradient(#fff 0%, #fff 50%, var(--level-four) 100%);
      }
    }

    &.level_five {
      border-color: var(--level-five);

      &::before {
        background: linear-gradient(#fff 0%, #fff 50%, var(--level-five) 100%);
      }
    }
  }

  & .reward_card-benefit {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  & .reward_card-content {
    background: linear-gradient(
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    padding: 1rem 1rem 2.25rem;
    position: relative;
    z-index: 2;

    &.not_reached {
        color: #8790a2;
    }
  }

  & .reward_card-content-heading {
    font-size: 0.9rem;
  }

  & .reward_card-content-description {
    font-size: 0.75rem;
    margin-top: 0.198rem;
  }

  & .reward_card-not_reached {
    align-items: flex-end;
    background-color: rgba(255, 255, 255, 0.75);
    display: flex;
    height: 100%;
    justify-content: flex-start;
    left: 0;
    padding: 1rem;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  & .reward_card-not_reached-content {
    color: #8790a2;
    left: 1rem;
    position: absolute;
    top: 1rem;
    width: calc(100% - 1rem);
  }

  /* Hide the missing percentage when the level is reached */
  &.is_reached .reward_card-not_reached {
    display: none;
  }

  & .reward_card-not_reached-missing_perc {
    border-radius: 7px;
    box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25),
      0 3px 7px -3px rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0.296rem 0.667rem;
    white-space: nowrap;
  }

  /* Missing percent variants */
  &.level_one .reward_card-not_reached-missing_perc {
    background-color: var(--level-one);
  }

  &.level_two .reward_card-not_reached-missing_perc {
    background-color: var(--level-two);
  }

  &.level_three .reward_card-not_reached-missing_perc {
    background-color: var(--level-three);
  }

  &.level_four .reward_card-not_reached-missing_perc {
    background-color: var(--level-four);
  }

  &.level_five .reward_card-not_reached-missing_perc {
    background-color: var(--level-five);
  }

  & .reward_card-icon {
    bottom: 0.75rem;
    right: 1rem;
    position: absolute;
    z-index: 2;
  }

  & .reward_card-icon .reward_icon {
    height: 60px;
    width: 60px;

    & .reward_icon-icon {
      height: 40px;
      width: 40px;
    }
  }

  /* Icon variants */
  &.level_one.is_reached .reward_icon {
    background-color: var(--level-one);
  }

  &.level_two.is_reached .reward_icon {
    background-color: var(--level-two);
  }

  &.level_three.is_reached .reward_icon {
    background-color: var(--level-three);
  }

  &.level_four.is_reached .reward_icon {
    background-color: var(--level-four);
  }

  &.level_five.is_reached .reward_icon {
    background-color: var(--level-five);
  }

  & .reward_card-description {
    font-size: 1rem;
  }
}
