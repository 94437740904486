@custom-media --small (width <= 960px);

.header_dashboard {
  align-items: center;
  background: #f7f7f7;
  box-shadow: rgba(50, 50, 93, 0.09) 0 2px 5px 0,
    rgba(0, 0, 0, 0.07) 0 1px 2px 0;
  color: #0c042e;
  display: flex;
  font-weight: 600;
  height: 72px;
  justify-content: space-between;
  padding: 0 1.5rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: var(--z-header);

  @media (--small) {
    padding: 0 1rem;
  }

  & .header-logo {
    cursor: pointer;
    height: 2.25rem;
  }

  & .header-nav {
    position: relative;
  }

  & .header-how_it_works {
    margin: 0 2.25rem 0 auto;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    @media (--small) {
      display: none;
    }
  }

  & .header-tabula_roadmap {
    display: inline-block;
    margin-right: 1.5rem;

    & > img {
      height: 3rem;
    }
  }

  & .header-nav-account {
    align-items: center;
    cursor: pointer;
    display: flex;
    transition: opacity 0.1s ease;
    user-select: none;

    &:hover {
      opacity: 0.5;
    }
  }

  & .header-nav-account-avatar {
    border-radius: 50%;
    height: 48px;
    position: relative;
    overflow: hidden;
    width: 48px;
  }

  & .header-nav-account-avatar-container {
    height: 100%;
    border: 3px solid #0c042e;
    border-radius: 50%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  & .header-nav-dropdown-mobile {
    display: none;
    @media (--small) {
      display: block;
    }
  }

  & .header-nav-account-label {
    margin-left: 0.5rem;
    position: relative;
    text-transform: uppercase;

    @media (--small) {
      display: none;
    }
  }

  & .header-nav-account-chevron {
    height: 100%;
    margin-left: 0.5rem;
    transition: transform 0.2s ease;
    width: 16px;

    @media (--small) {
      display: none;
    }

    &.is_dropdown_shown {
      transform: rotate(-180deg);
    }
  }

  & .header-nav-dropdown {
    background: #fff;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.1) 0 15px 35px 0,
      rgba(0, 0, 0, 0.07) 0 5px 15px 0;
    min-width: 100%;
    padding: 0.5rem;
    position: absolute;
    right: 0;
    top: 100%;

    transition: transform 0.25s ease, opacity 0.25s ease, visibility 0.25s ease;
    transform: scale3d(1, 0.9, 1);
    transform-origin: top center;
    opacity: 0;
    visibility: hidden;

    &.is_dropdown_shown {
      opacity: 1;
      transform: scale3d(1, 1, 1);
      visibility: visible;
    }

    & li {
      cursor: pointer;
      padding: 0.5rem;
      text-transform: uppercase;
      transition: opacity 0.1s ease;
      white-space: nowrap;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
