@custom-media --medium (width <= 1440px);
@custom-media --small (width < 768px);

.friends_invites {
  background-color: #fff;
  border: 2px solid #858196;
  border-radius: 1rem;
  box-shadow: var(--shadow-2);
  padding: 2.369rem 3.157rem;

  @media (--medium) {
    padding: 2.369rem 1.777rem;
  }

  @media (--small) {
    padding: 1.333rem 1rem;
  }

  & .friends_invites-list {
    margin-top: 1.333rem;
  }

  & .friends_invites-list-email {
    align-items: center;
    display: flex;

    &.sent {
      & .friends_invites-list-email-status {
        background: rgba(244, 132, 0, 0.2);

        &::after {
          background: rgba(244, 132, 0, 1);
        }
      }
    }

    &.clicked {
      & .friends_invites-list-email-status {
        background: rgba(70, 133, 255, 0.2);

        &::after {
          background: rgba(70, 133, 255, 1);
        }
      }
    }

    &.discount {
      & .friends_invites-list-email-status {
        background: rgba(0, 185, 43, 0.2);

        &::after {
          background: rgba(0, 185, 43, 1);
        }
      }
    }

    &.no_discount {
      & .friends_invites-list-email-status {
        background: rgba(254, 0, 0, 0.2);

        &::after {
          background: rgba(254, 0, 0, 1);
        }
      }
    }

    & + .friends_invites-list-email {
      margin-top: 0.422rem;
    }

    & span {
      color: #858196;
    }
  }

  & .friends_invites-list-email-status {
    align-items: center;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    height: 1.777rem;
    margin-right: 0.75rem;
    position: relative;
    width: 1.777rem;

    &::after {
      border-radius: 50%;
      content: "";
      height: 1.333rem;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1.333rem;
    }

    & svg {
      position: relative;
      z-index: 1;
    }
  }
}
