@import "https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap";
:root {
  --level-one: #c40018;
  --level-two: #f15a24;
  --level-three: #f4bb00;
  --level-four: #03c47b;
  --level-five: #29abe2;
  --header-height: 72px;
  --shadow-1: #32325d17 0 2px 5px 0, #00000012 0 1px 2px 0;
  --shadow-2: #32325d17 0 4px 6px 0, #00000014 0 1px 3px 0;
  --shadow-3: #00000012 0 1px 5px 0, #0000001a 0 7px 17px 0;
  --shadow-4: #32325d1a 0 15px 35px 0, #00000012 0 5px 15px 0;
  --shadow-5: #32325d26 0 15px 35px 0, #0000001a 0 5px 15px 0;
  --z-header: 10;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  color: #0c042e;
  font: 18px / 1.5 Montserrat, sans-serif;
}

@media (width <= 767px) {
  html {
    font-size: 16px;
  }
}

body {
  min-height: 100%;
}

*, :before, :after {
  box-sizing: inherit;
}

p {
  margin: 0;
}

h1, h2, h3, h4 {
  font-weight: inherit;
  margin: 0;
  line-height: 1.25;
}

h1 {
  text-transform: uppercase;
  font-size: 2.369rem;
  font-weight: 700;
}

h2 {
  text-transform: uppercase;
  font-size: 1.333rem;
  font-weight: 700;
}

h3 {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
}

h4 {
  font-weight: 700;
}

sub {
  color: #8790a2;
  vertical-align: baseline;
  font-size: .75rem;
  display: inline-block;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

figure {
  margin: 0;
}

img {
  width: auto;
  height: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

button {
  color: inherit;
  -webkit-appearance: none;
  border: none;
  outline: none;
  width: auto;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  overflow: visible;
}

fieldset {
  border: none;
  padding: 0;
}

.activity_history {
  width: 100%;
}

.activity_history .activity_history-purchase {
  display: flex;
}

.activity_history .activity_history-purchase:not(:last-of-type) {
  border-bottom: 2px solid #0c042e;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.activity_history .activity_history-purchase-image {
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  flex-shrink: 0;
  width: 92px;
  height: 92px;
}

.activity_history .activity_history-purchase-content {
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
  display: flex;
}

@media (width <= 768px) {
  .activity_history .activity_history-purchase-content {
    flex-direction: column;
    padding-right: 0;
  }
}

.activity_history .activity_history-purchase-content-product {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.activity_history .activity_history-purchase-content-product-description {
  margin-top: .132rem;
}

@media (width <= 768px) {
  .activity_history .activity_history-purchase-content-product-description {
    font-size: .75rem;
  }
}

.activity_history .activity_history-purchase-content-product-boost {
  color: #fff;
  text-transform: uppercase;
  background-color: #0c042e;
  border-radius: 7px;
  align-items: center;
  margin-top: .296rem;
  padding: .296rem .667rem;
  font-size: .667rem;
  font-weight: 700;
  display: flex;
}

.activity_history .boost-battery {
  height: .75rem;
  margin-left: 6px;
}

.activity_history .activity_history-purchase-content-purchase_info {
  font-size: .667rem;
}

@media (width <= 768px) {
  .activity_history .activity_history-purchase-content-purchase_info {
    margin-top: .444rem;
  }
}

.activity_history .activity_history-purchase-content-purchase_info > span {
  vertical-align: super;
  font-size: .444em;
}

.banner {
  background-color: #f4bb00;
  width: 100%;
  margin: 72px 0 -72px;
  padding: .25rem 1rem;
}

@media (width < 1024px) {
  .banner {
    padding: .25rem;
  }
}

.banner .banner-wrapper {
  grid-column-gap: 1rem;
  grid-template-columns: 1rem 1fr 1rem;
  grid-template-areas: "empty content close";
  align-items: center;
  width: 100%;
  max-width: 1600px;
  display: grid;
}

@media (width < 1024px) {
  .banner .banner-wrapper {
    grid-template-columns: 1fr 1.5rem;
    grid-template-areas: "content close";
    align-items: flex-start;
  }
}

.banner .banner-content {
  text-transform: uppercase;
  grid-area: content;
  justify-content: center;
  align-items: center;
  font-size: .65rem;
  font-weight: 700;
  display: flex;
}

@media (width < 1024px) {
  .banner .banner-content {
    flex-direction: column;
    align-items: flex-start;
  }
}

.banner .banner-content .button {
  white-space: nowrap;
  flex-shrink: 0;
  margin-left: 1rem;
}

@media (width < 1024px) {
  .banner .banner-content .button {
    margin-top: .25rem;
    margin-left: 0;
  }
}

.banner .banner-close {
  cursor: pointer;
  grid-area: close;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

@media (width < 1024px) {
  .banner .banner-close {
    height: 1.5rem;
  }
}

.banner .banner-close > svg {
  fill: #ececec;
  width: .75rem;
  height: .75rem;
}

.banner .banner-close:hover svg {
  fill: #fff;
}

.battery {
  --battery-height: 75vh;
  --battery-border-width: 18px;
  --battery-positive-height: 52px;
  height: var(--battery-height);
  flex-direction: column;
  width: 212px;
  min-height: 680px;
  display: flex;
}

@media (width <= 768px) {
  .battery {
    --battery-border-width: 8px;
    --battery-height: 104px;
    --battery-positive-width: 24px;
  }
}

@media (width <= 768px) {
  .battery {
    width: 100%;
    height: auto;
    min-height: 0;
  }
}

.battery .battery-indicator {
  color: #fff;
  text-align: center;
  z-index: 5;
  background-color: #0c042e;
  border-radius: 9px 9px 0 0;
  width: 160px;
  margin: 0 auto 2.25rem;
  padding: .667rem;
  position: relative;
}

@media (width <= 768px) {
  .battery .battery-indicator {
    width: 128px;
    margin-bottom: calc(1rem - 4px);
  }
}

.battery .battery-indicator:after {
  content: "";
  border-top: 1rem solid #0c042e;
  border-left: 80px solid #0000;
  border-right: 80px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 100%;
  left: 0;
}

@media (width <= 768px) {
  .battery .battery-indicator:after {
    border-left-width: 64px;
    border-right-width: 64px;
  }
}

.battery .battery-indicator-percentage {
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1;
}

@media (width <= 768px) {
  .battery .battery-indicator-percentage {
    font-size: 1.5rem;
  }
}

.battery .battery-indicator-level {
  text-transform: uppercase;
  margin-top: .296rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
}

@media (width <= 768px) {
  .battery .battery-indicator-level {
    font-size: .75rem;
  }
}

.battery .battery-shape {
  border: var(--battery-border-width) solid #bdccd4;
  border-radius: calc(var(--battery-border-width) * 2);
  padding: var(--battery-border-width);
  margin-top: calc(var(--battery-positive-height)  - var(--battery-border-width));
  background-color: #fff;
  height: 100%;
  position: relative;
}

@media (width <= 768px) {
  .battery .battery-shape {
    height: var(--battery-height);
    width: calc(100% - var(--battery-positive-width)  + var(--battery-border-width));
    margin-top: 0;
    top: 0;
  }
}

.battery .battery-shape:after {
  border-left: var(--battery-border-width) solid #bdccd4;
  border-right: var(--battery-border-width) solid #bdccd4;
  border-top: var(--battery-border-width) solid #bdccd4;
  content: "";
  height: var(--battery-positive-height);
  background-color: #fff;
  border-radius: 24px 24px 0 0;
  width: 86px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%);
}

@media (width <= 768px) {
  .battery .battery-shape:after {
    border-left: none;
    border-bottom: var(--battery-border-width) solid #bdccd4;
    width: var(--battery-positive-width);
    border-radius: 0 12px 12px 0;
    top: 50%;
    left: 100%;
    transform: translate(0, -50%);
  }
}

.battery .battery-shape:before {
  border-bottom: var(--battery-border-width) solid #fff;
  border-left: calc(var(--battery-border-width) * 1.333) solid transparent;
  border-right: calc(var(--battery-border-width) * 1.333) solid transparent;
  content: "";
  z-index: 1;
  width: 74px;
  height: 0;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%);
}

@media (width <= 768px) {
  .battery .battery-shape:before {
    border-left: var(--battery-border-width) solid #fff;
    border-bottom: calc(var(--battery-border-width) * 1.333) solid transparent;
    border-top: calc(var(--battery-border-width) * 1.333) solid transparent;
    height: var(--battery-positive-height);
    top: 50%;
    left: 100%;
    transform: translate(0, -50%);
  }
}

.battery .battery-marks {
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  display: flex;
}

@media (width <= 768px) {
  .battery .battery-marks {
    flex-direction: row-reverse;
  }
}

.battery .battery-marks-mark {
  border-radius: calc(var(--battery-border-width) / 2);
  background-color: #dde4e8;
  flex: 1;
  width: 100%;
  position: relative;
}

.battery .battery-marks-mark:after {
  border-radius: calc(var(--battery-border-width) / 2);
  content: "";
  transform-origin: bottom;
  width: 100%;
  height: 100%;
  animation: 1s linear forwards vertically_battery_level;
  position: absolute;
  transform: scale3d(1, 0, 1);
}

@media (width <= 768px) {
  .battery .battery-marks-mark:after {
    transform-origin: 0;
    animation-name: horizontally_battery_level;
    transform: scale3d(0, 1, 1);
  }
}

.battery .battery-marks-mark.level_one:after {
  background-color: var(--level-one);
  animation-timing-function: ease-in;
  animation-delay: 0s;
}

.battery .battery-marks-mark.level_two:after {
  background-color: var(--level-two);
  animation-delay: 1s;
}

.battery .battery-marks-mark.level_three:after {
  background-color: var(--level-three);
  animation-delay: 2s;
}

.battery .battery-marks-mark.level_four:after {
  background-color: var(--level-four);
  animation-delay: 3s;
}

.battery .battery-marks-mark.level_five:after {
  background-color: var(--level-five);
  animation-delay: 4s;
}

.battery .battery-marks-mark + .battery-marks-mark {
  margin-top: var(--battery-border-width);
  animation-delay: 5s;
}

@media (width <= 768px) {
  .battery .battery-marks-mark + .battery-marks-mark {
    margin-top: 0;
    margin-right: var(--battery-border-width);
  }
}

.battery .battery-marks-mark > .reward_icon {
  z-index: 2;
  background-color: #8790a2;
  width: 71px;
  height: 71px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale3d(1, 1, 1);
}

@media (width <= 768px) {
  .battery .battery-marks-mark > .reward_icon {
    width: 34px;
    height: 34px;
  }
}

.battery .battery-marks-mark > .reward_icon[class*="level_"] {
  background-color: #8790a2;
  transition: none;
}

.battery .battery-marks-mark > .reward_icon.is_reached.level_one {
  animation: 1s cubic-bezier(.455, .03, .515, .955) forwards enable_icon_level_one;
}

.battery .battery-marks-mark > .reward_icon.is_reached.level_two {
  animation: 1s cubic-bezier(.455, .03, .515, .955) 1s forwards enable_icon_level_two;
}

.battery .battery-marks-mark > .reward_icon.is_reached.level_three {
  animation: 1s cubic-bezier(.455, .03, .515, .955) 2s forwards enable_icon_level_three;
}

.battery .battery-marks-mark > .reward_icon.is_reached.level_four {
  animation: 1s cubic-bezier(.455, .03, .515, .955) 3s forwards enable_icon_level_four;
}

.battery .battery-marks-mark > .reward_icon.is_reached.level_five {
  animation: 1s cubic-bezier(.455, .03, .515, .955) 4s forwards enable_icon_level_five;
}

.battery .battery-marks-mark > .reward_icon .reward_icon-icon {
  width: 47px;
  height: 47px;
}

@media (width <= 768px) {
  .battery .battery-marks-mark > .reward_icon .reward_icon-icon {
    width: 23px;
    height: 23px;
  }
}

@keyframes vertically_battery_level {
  from {
    transform: scale3d(1, 0, 1);
  }

  to {
    transform: scale3d(1, var(--battery-level), 1);
  }
}

@keyframes horizontally_battery_level {
  from {
    transform: scale3d(0, 1, 1);
  }

  to {
    transform: scale3d(var(--battery-level), 1, 1);
  }
}

@keyframes enable_icon_level_one {
  0% {
    background-color: #8790a2;
    transform: translate(-50%, -50%)scale3d(1, 1, 1);
  }

  25% {
    background-color: #8790a2;
    transform: translate(-50%, -50%)scale3d(1.25, 1.25, 1.25);
  }

  50% {
    background-color: var(--level-one);
    transform: translate(-50%, -50%)scale3d(1, 1, 1);
  }

  100% {
    background-color: var(--level-one);
    transform: translate(-50%, -50%)scale3d(1, 1, 1);
  }
}

@keyframes enable_icon_level_two {
  0% {
    background-color: #8790a2;
    transform: translate(-50%, -50%)scale3d(1, 1, 1);
  }

  25% {
    background-color: #8790a2;
    transform: translate(-50%, -50%)scale3d(1.25, 1.25, 1.25);
  }

  50% {
    background-color: var(--level-two);
    transform: translate(-50%, -50%)scale3d(1, 1, 1);
  }

  100% {
    background-color: var(--level-two);
    transform: translate(-50%, -50%)scale3d(1, 1, 1);
  }
}

@keyframes enable_icon_level_three {
  0% {
    background-color: #8790a2;
    transform: translate(-50%, -50%)scale3d(1, 1, 1);
  }

  25% {
    background-color: #8790a2;
    transform: translate(-50%, -50%)scale3d(1.25, 1.25, 1.25);
  }

  50% {
    background-color: var(--level-three);
    transform: translate(-50%, -50%)scale3d(1, 1, 1);
  }

  100% {
    background-color: var(--level-three);
    transform: translate(-50%, -50%)scale3d(1, 1, 1);
  }
}

@keyframes enable_icon_level_four {
  0% {
    background-color: #8790a2;
    transform: translate(-50%, -50%)scale3d(1, 1, 1);
  }

  25% {
    background-color: #8790a2;
    transform: translate(-50%, -50%)scale3d(1.25, 1.25, 1.25);
  }

  50% {
    background-color: var(--level-four);
    transform: translate(-50%, -50%)scale3d(1, 1, 1);
  }

  100% {
    background-color: var(--level-four);
    transform: translate(-50%, -50%)scale3d(1, 1, 1);
  }
}

@keyframes enable_icon_level_five {
  0% {
    background-color: #8790a2;
    transform: translate(-50%, -50%)scale3d(1, 1, 1);
  }

  25% {
    background-color: #8790a2;
    transform: translate(-50%, -50%)scale3d(1.25, 1.25, 1.25);
  }

  50% {
    background-color: var(--level-five);
    transform: translate(-50%, -50%)scale3d(1, 1, 1);
  }

  100% {
    background-color: var(--level-five);
    transform: translate(-50%, -50%)scale3d(1, 1, 1);
  }
}

.button {
  box-shadow: var(--shadow-2);
  cursor: pointer;
  letter-spacing: .025em;
  text-align: center;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 50px;
  min-width: 150px;
  padding: .75rem 1.5rem;
  font-weight: 700;
  text-decoration: none;
  transition: box-shadow .15s;
  display: inline-block;
}

.button:hover {
  box-shadow: var(--shadow-3);
}

.button:active {
  box-shadow: var(--shadow-2);
}

.button.small {
  padding: .45rem 1rem;
  font-size: .65rem;
}

.button.button_primary {
  color: #0c042e;
  background-color: #f4bb00;
}

.button.button_secondary {
  color: #858196;
  background-color: #fff;
  border: 2px solid #858196;
}

.button.button_danger {
  color: #fe0000;
  border: 2px solid #fe0000;
}

.button.button_friends {
  background-color: #4edfab;
}

.ghost_button {
  color: #0c042e;
  cursor: pointer;
  text-decoration: underline;
}

.contained_form {
  box-shadow: var(--shadow-2);
  background-color: #fff;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 622px;
  padding: 3.375rem;
  display: flex;
}

@media (width < 768px) {
  .contained_form {
    max-width: none;
    padding: 2.25rem 1rem;
  }
}

@media (width < 768px) {
  .contained_form h2 {
    font-size: 1rem;
  }
}

.contained_form .contained_form-form {
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 2.25rem;
  display: flex;
}

.contained_form .contained_form-form > .text_input + .text_input {
  margin-top: 1rem;
}

.contained_form .contained_form-form > .button {
  margin-top: 1.5rem;
}

.contained_form .contained_form-message {
  text-align: center;
}

.contained_form .contained_form-message > * + * {
  margin-top: 1.5rem;
}

.contained_form .contained_form-message .contained_form-message-heading {
  font-size: 1.25rem;
}

.contained_form .contained_form-message .contained_form-message-accent {
  font-size: 1.25rem;
  font-weight: 600;
}

.contained_form .contained_form-message .contained_form-message-content {
  font-size: 1rem;
}

.contained_form.invalid h3 {
  color: #fe0000;
}

.contained_form > .ghost_button {
  margin-top: 1.5rem;
}

.file_uploader {
  display: inline-block;
  position: relative;
}

.file_uploader .file_uploader-input {
  color: #0000;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.file_uploader .file_uploader-input::-webkit-file-upload-button {
  visibility: hidden;
}

.file_uploader .file_uploader-ui {
  box-shadow: var(--shadow-2);
  color: #858196;
  content: "Upload file";
  cursor: pointer;
  letter-spacing: .025em;
  text-align: center;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background-color: #fff;
  border: 2px solid #858196;
  border-radius: 50px;
  outline: none;
  min-width: 150px;
  padding: .444rem 1rem;
  font: 700 .75rem / 1.15 Montserrat, sans-serif;
  transition: box-shadow .15s;
  display: inline-block;
}

.file_uploader:hover .file_uploader-ui {
  box-shadow: var(--shadow-3);
}

.file_uploader:active .file_uploader-ui {
  box-shadow: var(--shadow-2);
}

.footer {
  z-index: 1;
  width: 100%;
  position: relative;
}

.footer .footer-main {
  background-color: #0c042e;
  padding: 3.375rem 1rem 1rem;
}

.footer .footer-main-container {
  flex-wrap: wrap;
  max-width: 1080px;
  margin: auto;
  display: flex;
}

.footer .footer-logo {
  flex-grow: 1;
  height: 3.375rem;
  margin: 0 1rem 2.25rem 0;
}

.footer .footer-nav {
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: space-between;
  display: flex;
}

.footer .footer-nav-column {
  margin: 0 1rem 2.25rem 0;
}

.footer .footer-nav-column-heading {
  color: #858196;
  margin-bottom: 1rem;
  font-size: .75rem;
}

.footer .footer-nav-column-list li {
  color: #fff;
  font-weight: 500;
}

.footer .footer-nav-column-list li + li {
  margin-top: .667rem;
}

.footer .footer-nav-column-list li a {
  transition: opacity .1s;
}

.footer .footer-nav-column-list li a:hover {
  opacity: .67;
}

.footer .footer-copyright {
  color: #fff;
  text-align: center;
  background-color: #07021a;
  padding: 1.5rem 1rem;
  font-size: .75rem;
}

.friends_form {
  box-shadow: var(--shadow-2);
  background-color: #fff;
  border-radius: 1rem;
  padding: 3.157rem;
}

@media (width <= 1440px) {
  .friends_form {
    padding: 2.369rem 1.777rem;
  }
}

@media (width < 768px) {
  .friends_form {
    padding: 1.333rem 1rem;
  }
}

.friends_form .friends_form-description > p {
  margin-top: 1rem;
}

.friends_form .friends_form-description > p + p {
  margin-top: .422rem;
}

.friends_form .friends_form-form {
  margin-top: 2.369rem;
}

.friends_form .friends_form-form .button {
  margin-top: 1rem;
}

.friends_invites {
  box-shadow: var(--shadow-2);
  background-color: #fff;
  border: 2px solid #858196;
  border-radius: 1rem;
  padding: 2.369rem 3.157rem;
}

@media (width <= 1440px) {
  .friends_invites {
    padding: 2.369rem 1.777rem;
  }
}

@media (width < 768px) {
  .friends_invites {
    padding: 1.333rem 1rem;
  }
}

.friends_invites .friends_invites-list {
  margin-top: 1.333rem;
}

.friends_invites .friends_invites-list-email {
  align-items: center;
  display: flex;
}

.friends_invites .friends_invites-list-email.sent .friends_invites-list-email-status {
  background: #f4840033;
}

.friends_invites .friends_invites-list-email.sent .friends_invites-list-email-status:after {
  background: #f48400;
}

.friends_invites .friends_invites-list-email.clicked .friends_invites-list-email-status {
  background: #4685ff33;
}

.friends_invites .friends_invites-list-email.clicked .friends_invites-list-email-status:after {
  background: #4685ff;
}

.friends_invites .friends_invites-list-email.discount .friends_invites-list-email-status {
  background: #00b92b33;
}

.friends_invites .friends_invites-list-email.discount .friends_invites-list-email-status:after {
  background: #00b92b;
}

.friends_invites .friends_invites-list-email.no_discount .friends_invites-list-email-status {
  background: #fe000033;
}

.friends_invites .friends_invites-list-email.no_discount .friends_invites-list-email-status:after {
  background: #fe0000;
}

.friends_invites .friends_invites-list-email + .friends_invites-list-email {
  margin-top: .422rem;
}

.friends_invites .friends_invites-list-email span {
  color: #858196;
}

.friends_invites .friends_invites-list-email-status {
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 1.777rem;
  height: 1.777rem;
  margin-right: .75rem;
  display: flex;
  position: relative;
}

.friends_invites .friends_invites-list-email-status:after {
  content: "";
  border-radius: 50%;
  width: 1.333rem;
  height: 1.333rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.friends_invites .friends_invites-list-email-status svg {
  z-index: 1;
  position: relative;
}

.header_dashboard {
  color: #0c042e;
  z-index: var(--z-header);
  background: #f7f7f7;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 0 1.5rem;
  font-weight: 600;
  display: flex;
  position: absolute;
  top: 0;
  box-shadow: 0 2px 5px #32325d17, 0 1px 2px #00000012;
}

@media (width <= 960px) {
  .header_dashboard {
    padding: 0 1rem;
  }
}

.header_dashboard .header-logo {
  cursor: pointer;
  height: 2.25rem;
}

.header_dashboard .header-nav {
  position: relative;
}

.header_dashboard .header-how_it_works {
  text-transform: uppercase;
  align-items: center;
  margin: 0 2.25rem 0 auto;
  display: flex;
}

@media (width <= 960px) {
  .header_dashboard .header-how_it_works {
    display: none;
  }
}

.header_dashboard .header-tabula_roadmap {
  margin-right: 1.5rem;
  display: inline-block;
}

.header_dashboard .header-tabula_roadmap > img {
  height: 3rem;
}

.header_dashboard .header-nav-account {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  transition: opacity .1s;
  display: flex;
}

.header_dashboard .header-nav-account:hover {
  opacity: .5;
}

.header_dashboard .header-nav-account-avatar {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  position: relative;
  overflow: hidden;
}

.header_dashboard .header-nav-account-avatar-container {
  border: 3px solid #0c042e;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.header_dashboard .header-nav-dropdown-mobile {
  display: none;
}

@media (width <= 960px) {
  .header_dashboard .header-nav-dropdown-mobile {
    display: block;
  }
}

.header_dashboard .header-nav-account-label {
  text-transform: uppercase;
  margin-left: .5rem;
  position: relative;
}

@media (width <= 960px) {
  .header_dashboard .header-nav-account-label {
    display: none;
  }
}

.header_dashboard .header-nav-account-chevron {
  width: 16px;
  height: 100%;
  margin-left: .5rem;
  transition: transform .2s;
}

@media (width <= 960px) {
  .header_dashboard .header-nav-account-chevron {
    display: none;
  }
}

.header_dashboard .header-nav-account-chevron.is_dropdown_shown {
  transform: rotate(-180deg);
}

.header_dashboard .header-nav-dropdown {
  transform-origin: top;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  border-radius: 10px;
  min-width: 100%;
  padding: .5rem;
  transition: transform .25s, opacity .25s, visibility .25s;
  position: absolute;
  top: 100%;
  right: 0;
  transform: scale3d(1, .9, 1);
  box-shadow: 0 15px 35px #32325d1a, 0 5px 15px #00000012;
}

.header_dashboard .header-nav-dropdown.is_dropdown_shown {
  opacity: 1;
  visibility: visible;
  transform: scale3d(1, 1, 1);
}

.header_dashboard .header-nav-dropdown li {
  cursor: pointer;
  text-transform: uppercase;
  white-space: nowrap;
  padding: .5rem;
  transition: opacity .1s;
}

.header_dashboard .header-nav-dropdown li:hover {
  opacity: .5;
}

.header_default {
  color: #fff;
  height: var(--header-height);
  z-index: var(--z-header);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1.5rem;
  font-weight: 600;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 1260px) {
  .header_default {
    align-items: flex-start;
    padding: 0;
  }
}

.header_default .header-logo {
  cursor: pointer;
  height: 2.25rem;
}

@media (width <= 1260px) {
  .header_default .header-logo {
    z-index: 1000;
    position: absolute;
    top: 50%;
    left: 1.5rem;
    transform: translateY(-50%);
  }
}

.header_default .header-hamburger {
  display: none;
}

@media (width <= 1260px) {
  .header_default .header-hamburger {
    cursor: pointer;
    z-index: 1000;
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 1.15rem;
    transform: translateY(-50%);
  }
}

.header_default .header-hamburger-inner {
  background-color: #fff;
  width: 1.5rem;
  height: 3px;
  transition-property: transform;
  transition-duration: 75ms;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  position: absolute;
  top: 1rem;
  left: .35rem;
}

.header_default .header-hamburger-inner.is_nav_shown {
  transition-delay: .12s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: rotate(45deg);
}

.header_default .header-hamburger-inner:after, .header_default .header-hamburger-inner:before {
  content: "";
  background-color: #fff;
  height: 3px;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: ease;
  display: block;
  position: absolute;
}

.header_default .header-hamburger-inner:after {
  width: 20px;
  transition: bottom 75ms .12s, opacity 75ms;
  bottom: -.5rem;
}

.header_default .header-hamburger-inner.is_nav_shown:after {
  transition: bottom 75ms, opacity 75ms .12s;
  bottom: 0;
}

.header_default .header-hamburger-inner:before {
  width: 1.5rem;
  transition: top 75ms .12s, transform 75ms cubic-bezier(.55, .055, .675, .19);
  top: -.5rem;
}

.header_default .header-hamburger-inner.is_nav_shown:before {
  transition: top 75ms, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
  top: 0;
  transform: rotate(-90deg);
}

@media (width <= 1260px) {
  .header_default .header-nav {
    pointer-events: none;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }
}

.header_default .header-nav.is_nav_shown {
  pointer-events: all;
}

.header_default .header-nav-list {
  align-items: center;
  display: flex;
}

@media (width <= 1260px) {
  .header_default .header-nav-list {
    background: linear-gradient(315deg, #0c042e 0%, #003564 100%);
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: calc(72px + 1.5rem) 1rem;
    transition: transform .2s;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.header_default .header-nav-list.is_nav_shown {
  transition-duration: .25s;
  transform: translate3d(0, 0, 0);
}

.header_default .header-nav-list-link {
  cursor: pointer;
  text-transform: uppercase;
  transition: opacity .1s;
  position: relative;
}

@media (width <= 1260px) {
  .header_default .header-nav-list-link {
    text-align: center;
    font-size: 1.5rem;
  }
}

.header_default .header-nav-list-link:hover {
  opacity: .67;
}

.header_default .header-nav-list-link + .header-nav-list-link {
  margin-left: 1.5rem;
}

@media (width <= 1260px) {
  .header_default .header-nav-list-link + .header-nav-list-link {
    margin-top: 1.5rem;
    margin-left: 0;
  }
}

.header_default .header-nav-list-link-tabula_roadmap {
  display: inline-block;
}

.header_default .header-nav-list-link-tabula_roadmap > img {
  height: 3rem;
}

.header_default .header-nav-list-link.header-nav-list-login_link {
  margin-left: 5.063rem;
}

@media (width <= 1260px) {
  .header_default .header-nav-list-link.header-nav-list-login_link {
    margin-top: 3rem;
    margin-left: 0;
    padding: 0 1rem;
    font-size: 1rem;
    position: relative;
  }
}

@media (width <= 1260px) {
  .header_default .header-nav-list-link.header-nav-list-login_link:before {
    content: "";
    cursor: default;
    background: #fff;
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: calc(100% + 1.5rem);
    left: 0;
  }
}

.header_default .header-nav-list-login_link a {
  align-items: center;
  display: flex;
}

.header_default .header-nav-list-login_link svg {
  width: auto;
  height: 14px;
  margin-left: .5rem;
}

.header_default.header_info {
  color: #0c042e;
  background: #f7f7f7;
  box-shadow: 0 2px 5px #32325d17, 0 1px 2px #00000012;
}

.header_default.header_info .header-nav-list-link-login_chevron path {
  fill: #0c042e;
}

.header_default.header_info .header-hamburger-inner, .header_default.header_info .header-hamburger-inner:after, .header_default.header_info .header-hamburger-inner:before {
  background-color: #003564;
}

@media (width <= 1260px) {
  .header_default.header_info .header-nav-list {
    background: linear-gradient(315deg, #d9d9d9 0%, #f7f7f7 100%);
  }
}

.header_default.header_info .header-nav-list-link.header-nav-list-login_link:before {
  background-color: #003564;
}

.info_card {
  box-shadow: var(--shadow-3);
  text-align: center;
  background-color: #fff;
  border-radius: 50px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 340px;
  padding: 2.25rem;
  display: flex;
}

.info_card > * + * {
  margin-top: 1.5rem;
}

.info_card .info_card-heading {
  text-transform: uppercase;
  font-weight: 700;
}

.info_card .info_card-icon {
  background-color: #f4bb00;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  display: inline-flex;
}

.info_card .info_card-icon img {
  width: 53px;
  height: 53px;
}

.info_card .info_card-description {
  font-size: 1rem;
}

.landing_rewards {
  color: #0c042e;
  background-color: #ffffffb3;
  border: 2px solid #05ce78;
  border-radius: 20px;
  width: 100%;
  max-width: 810px;
  padding: 1.5rem;
}

@media (width < 768px) {
  .landing_rewards {
    padding: .667rem;
  }
}

.landing_rewards .landing_rewards-list-item {
  align-items: center;
  display: flex;
}

@media (width < 768px) {
  .landing_rewards .landing_rewards-list-item {
    align-items: baseline;
  }
}

.landing_rewards .landing_rewards-list-item + .landing_rewards-list-item {
  margin-top: 1rem;
}

@media (width < 768px) {
  .landing_rewards .landing_rewards-list-item + .landing_rewards-list-item {
    margin-top: .667rem;
  }
}

.landing_rewards .reward_icon {
  width: 48px;
  height: 48px;
}

@media (width < 768px) {
  .landing_rewards .reward_icon {
    width: 32px;
    height: 32px;
  }
}

.landing_rewards .reward_icon .reward_icon-icon {
  width: 32px;
  height: 32px;
}

@media (width < 768px) {
  .landing_rewards .reward_icon .reward_icon-icon {
    width: 22px;
    height: 22px;
  }
}

.landing_rewards .landing_rewards-list-item-info {
  align-items: center;
  width: 100%;
  margin-left: 1rem;
  display: flex;
}

@media (width < 768px) {
  .landing_rewards .landing_rewards-list-item-info {
    flex-direction: column;
    align-items: flex-start;
    margin-left: .444rem;
    position: relative;
    top: -4px;
  }
}

.landing_rewards .landing_rewards-list-item-info-name {
  margin-right: auto;
  line-height: 1.25;
}

.landing_rewards .landing_rewards-list-item.not_gained .landing_rewards-list-item-info-name {
  color: #8790a2;
}

.landing_rewards .landing_rewards-list-item-info-degrees {
  color: #fff;
  white-space: nowrap;
  border-radius: 7px;
  margin-left: 1rem;
  padding: .296rem .667rem;
  font-size: .75rem;
  font-weight: 600;
}

@media (width < 768px) {
  .landing_rewards .landing_rewards-list-item-info-degrees {
    margin-top: .296rem;
    margin-left: 0;
    line-height: 1;
  }
}

.landing_rewards .landing_rewards-list-item-info-degrees.level_one {
  background-color: var(--level-one);
}

.landing_rewards .landing_rewards-list-item-info-degrees.level_two {
  background-color: var(--level-two);
}

.landing_rewards .landing_rewards-list-item-info-degrees.level_three {
  background-color: var(--level-three);
}

.landing_rewards .landing_rewards-list-item-info-degrees.level_four {
  background-color: var(--level-four);
}

.landing_rewards .landing_rewards-list-item-info-degrees.level_five {
  background-color: var(--level-five);
}

.modal {
  z-index: 100;
  background-color: #ffffff80;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 1rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

@media (width <= 640px) {
  .modal {
    padding: 0;
  }
}

.modal .modal-container {
  box-shadow: var(--shadow-4);
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  max-width: 768px;
}

.modal.success .modal-container {
  border: 3px solid #00b92b;
}

.modal.alert .modal-container {
  border: 3px solid #fe0000;
}

.modal .modal-content {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding: 2.25rem;
  display: flex;
}

@media (width <= 640px) {
  .modal .modal-content {
    padding: 2.25rem 1rem;
  }
}

.modal .modal-content > * + * {
  margin-top: 1rem;
}

.modal .modal-content-actions {
  justify-content: center;
  margin-top: 2.25rem;
  display: flex;
}

@media (width <= 640px) {
  .modal .modal-content-actions > * {
    padding: .444rem 1rem;
    font-size: .75rem;
    display: inline-block;
  }
}

.modal .modal-content-actions > * + * {
  margin-left: 1.5rem;
}

@media (width <= 640px) {
  .modal .modal-content-actions > * + * {
    margin-left: 1rem;
  }
}

.pledge_card {
  box-shadow: var(--shadow-2);
  color: #0c042e;
  cursor: pointer;
  background-color: #fff;
  border-radius: 12px;
  width: 298px;
  min-height: 364px;
  margin: .5rem;
  transition: all .2s;
  display: flex;
  overflow: hidden;
  transform: scale3d(1, 1, 1);
}

.pledge_card:hover {
  transform: scale3d(1.034, 1.034, 1);
}

.pledge_card .pledge_card-container {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.pledge_card .pledge_card-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 182px;
}

.pledge_card .pledge_card-content {
  border-top: 1px solid #e8e8e8;
  padding: 1rem;
}

.pledge_card .pledge_card-content-description {
  margin-top: .667rem;
  font-size: .75rem;
}

.pledge_card .pledge_card-info {
  flex: 1 0 auto;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 1rem 1rem;
  display: flex;
}

.pledge_card .pledge_card-info-boost {
  color: #fff;
  text-transform: uppercase;
  background-color: #0c042e;
  border-radius: 7px;
  align-items: center;
  margin-top: .296rem;
  padding: .296rem .667rem;
  font-size: .667rem;
  font-weight: 700;
  display: flex;
}

.pledge_card .boost-battery {
  height: .75rem;
  margin-left: 6px;
}

.pledge_card .pledge_card-info-price {
  color: #a7adb3;
  font-size: .75rem;
}

.policy_card {
  box-shadow: var(--shadow-2);
  background-color: #fff;
  border-radius: 20px;
  padding: 1rem 1.5rem 1.5rem;
}

.policy_card > h2 {
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  padding-bottom: .444rem;
}

.policy_card:not(:first-child) > h2 {
  text-transform: none;
}

.policy_card .policy_card-content {
  padding-top: 1rem;
  font-size: .75rem;
}

.policy_card .policy_card-content > * + * {
  margin-top: .444rem;
}

.project_ad {
  background-color: #fff;
  width: 100%;
  padding: 0 1rem;
  position: relative;
}

@media (width < 1240px) {
  .project_ad {
    background: #fff;
  }
}

.project_ad .project_ad-container {
  z-index: 2;
  align-items: center;
  width: 100%;
  max-width: 1080px;
  margin: auto;
  padding: 5.063rem 0;
  display: flex;
  position: relative;
}

@media (width < 960px) {
  .project_ad .project_ad-container {
    flex-direction: column;
    max-width: 768px;
    padding: 3.375rem 0;
  }
}

.project_ad .project_ad-container > * {
  flex: 1;
}

.project_ad.dashboard .project_ad-container {
  max-width: 1280px;
}

.project_ad .project_ad-content > h2, .project_ad .project_ad-content > p + p {
  margin-top: 1rem;
}

.project_ad .project_ad-content > p:first-of-type, .project_ad .project_ad-content > .button {
  margin-top: 1.5rem;
}

.project_ad .project_ad-image {
  margin-left: 2.25rem;
}

@media (width < 960px) {
  .project_ad .project_ad-image {
    margin-top: 1.5rem;
    margin-left: 0;
  }
}

.project_ad .project_ad-image > img {
  box-shadow: var(--shadow-3);
  width: 100%;
  height: auto;
}

.project_ad.friends {
  background-color: #e5f3f9;
}

.project_ad.friends h2 {
  text-transform: none;
}

.project_ad.friends .project_ad-image {
  justify-content: center;
  display: flex;
}

.project_ad.friends img {
  box-shadow: none;
  max-width: 440px;
}

.reward_card {
  box-shadow: var(--shadow-3);
  background-color: #fff;
  border: 5px solid #8790a2;
  border-radius: 25px;
  align-items: center;
  width: 100%;
  max-width: 240px;
  height: 240px;
  position: relative;
  overflow: hidden;
}

.reward_card:before {
  content: "";
  opacity: .67;
  z-index: 0;
  background: linear-gradient(#fff 0% 50%, #8790a2 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.reward_card.is_reached.level_one {
  border-color: var(--level-one);
}

.reward_card.is_reached.level_one:before {
  background: linear-gradient(#fff 0%, #fff 50%, var(--level-one) 100%);
}

.reward_card.is_reached.level_two {
  border-color: var(--level-two);
}

.reward_card.is_reached.level_two:before {
  background: linear-gradient(#fff 0%, #fff 50%, var(--level-two) 100%);
}

.reward_card.is_reached.level_three {
  border-color: var(--level-three);
}

.reward_card.is_reached.level_three:before {
  background: linear-gradient(#fff 0%, #fff 50%, var(--level-three) 100%);
}

.reward_card.is_reached.level_four {
  border-color: var(--level-four);
}

.reward_card.is_reached.level_four:before {
  background: linear-gradient(#fff 0%, #fff 50%, var(--level-four) 100%);
}

.reward_card.is_reached.level_five {
  border-color: var(--level-five);
}

.reward_card.is_reached.level_five:before {
  background: linear-gradient(#fff 0%, #fff 50%, var(--level-five) 100%);
}

.reward_card .reward_card-benefit {
  z-index: 1;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.reward_card .reward_card-content {
  z-index: 2;
  background: linear-gradient(#fff 50%, #fff0 100%);
  padding: 1rem 1rem 2.25rem;
  position: relative;
}

.reward_card .reward_card-content.not_reached {
  color: #8790a2;
}

.reward_card .reward_card-content-heading {
  font-size: .9rem;
}

.reward_card .reward_card-content-description {
  margin-top: .198rem;
  font-size: .75rem;
}

.reward_card .reward_card-not_reached {
  z-index: 1;
  background-color: #ffffffbf;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.reward_card .reward_card-not_reached-content {
  color: #8790a2;
  width: calc(100% - 1rem);
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.reward_card.is_reached .reward_card-not_reached {
  display: none;
}

.reward_card .reward_card-not_reached-missing_perc {
  color: #fff;
  white-space: nowrap;
  border-radius: 7px;
  padding: .296rem .667rem;
  font-size: .75rem;
  font-weight: 600;
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.reward_card.level_one .reward_card-not_reached-missing_perc {
  background-color: var(--level-one);
}

.reward_card.level_two .reward_card-not_reached-missing_perc {
  background-color: var(--level-two);
}

.reward_card.level_three .reward_card-not_reached-missing_perc {
  background-color: var(--level-three);
}

.reward_card.level_four .reward_card-not_reached-missing_perc {
  background-color: var(--level-four);
}

.reward_card.level_five .reward_card-not_reached-missing_perc {
  background-color: var(--level-five);
}

.reward_card .reward_card-icon {
  z-index: 2;
  position: absolute;
  bottom: .75rem;
  right: 1rem;
}

.reward_card .reward_card-icon .reward_icon {
  width: 60px;
  height: 60px;
}

.reward_card .reward_card-icon .reward_icon .reward_icon-icon {
  width: 40px;
  height: 40px;
}

.reward_card.level_one.is_reached .reward_icon {
  background-color: var(--level-one);
}

.reward_card.level_two.is_reached .reward_icon {
  background-color: var(--level-two);
}

.reward_card.level_three.is_reached .reward_icon {
  background-color: var(--level-three);
}

.reward_card.level_four.is_reached .reward_icon {
  background-color: var(--level-four);
}

.reward_card.level_five.is_reached .reward_icon {
  background-color: var(--level-five);
}

.reward_card .reward_card-description {
  font-size: 1rem;
}

.reward_icon {
  background-color: #8790a2;
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 91px;
  height: 91px;
  display: inline-flex;
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

.reward_icon.is_reached.level_one {
  background-color: var(--level-one);
}

.reward_icon.is_reached.level_two {
  background-color: var(--level-two);
}

.reward_icon.is_reached.level_three {
  background-color: var(--level-three);
}

.reward_icon.is_reached.level_four {
  background-color: var(--level-four);
}

.reward_icon.is_reached.level_five {
  background-color: var(--level-five);
}

.reward_icon .reward_icon-icon {
  width: 61px;
  height: 61px;
}

.reward_icon .reward_icon-icon svg {
  fill: #fff;
}

.settings_card {
  box-shadow: var(--shadow-2);
  background-color: #fff;
  border-radius: 20px;
  padding: 1rem 1.5rem 1.5rem;
}

.settings_card .settings_card-header {
  border-bottom: 1px solid #e8e8e8;
  justify-content: space-between;
  padding-bottom: .444rem;
  display: flex;
}

@media (width <= 640px) {
  .settings_card .settings_card-header {
    flex-direction: column;
    align-items: flex-start;
  }
}

.settings_card .settings_card-header .button {
  padding: .444rem 1rem;
  font-size: .75rem;
  display: inline-block;
}

@media (width <= 640px) {
  .settings_card .settings_card-header .button {
    margin-top: 1rem;
  }
}

.settings_card .settings_card-form {
  padding-top: 1rem;
}

.settings_card .settings_card-form .button:not(:first-child) {
  margin-top: 1.5rem;
}

.settings_card .settings_card-form-container {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 1.5rem;
  display: flex;
}

@media (width <= 920px) {
  .settings_card .settings_card-form-container {
    flex-direction: column;
  }
}

.settings_card .settings_card-form-fieldset {
  flex: 1;
}

@media (width <= 460px) {
  .settings_card .settings_card-form-fieldset:first-child {
    align-self: center;
  }
}

.settings_card .settings_card-form-fieldset + .settings_card-form-fieldset {
  flex: 10;
  margin-left: 2.25rem;
}

@media (width <= 920px) {
  .settings_card .settings_card-form-fieldset + .settings_card-form-fieldset {
    margin-top: 1.5rem;
    margin-left: 0;
  }
}

.settings_card .settings_card-form-avatar .file_uploader {
  margin-top: .75rem;
}

.settings_card .settings_card-form-avatar-image {
  border: 10px solid #0c042e;
  border-radius: 50%;
  width: 165px;
  height: 165px;
  overflow: hidden;
}

.settings_card .settings_card-form-fieldset-row {
  align-items: flex-end;
  display: flex;
}

@media (width <= 640px) {
  .settings_card .settings_card-form-fieldset-row {
    flex-direction: column;
    align-items: flex-start;
  }
}

.settings_card .settings_card-form-fieldset-row + .settings_card-form-fieldset-row {
  margin-top: 1rem;
}

.settings_card .settings_card-form-fieldset-row > * {
  flex: 1;
}

.settings_card .settings_card-form-fieldset-row > * + * {
  margin-left: 1rem;
}

@media (width <= 640px) {
  .settings_card .settings_card-form-fieldset-row > * + * {
    margin-top: 1rem;
    margin-left: 0;
  }
}

@media (width <= 640px) {
  .settings_card .settings_card-form-fieldset-row-empty {
    display: none;
  }
}

.sidenav .sidenav-navs {
  height: 100vh;
  padding-top: 2.369rem;
  position: sticky;
  top: 0;
  overflow-y: auto;
}

@media (width < 768px) {
  .sidenav .sidenav-navs {
    height: auto;
    padding-bottom: 1rem;
  }
}

.sidenav .sidenav-navs-nav.is_expanded .sidenav-navs-nav-button-chevron {
  transform: rotate(90deg);
}

.sidenav .sidenav-navs-nav.is_expanded .sidenav-navs-nav-list {
  height: auto;
}

.sidenav .sidenav-navs-nav + .sidenav-navs-nav {
  margin-top: 1rem;
}

.sidenav .sidenav-navs-nav-button {
  align-items: baseline;
  display: flex;
}

.sidenav .sidenav-navs-nav-button-chevron {
  justify-content: center;
  align-items: center;
  width: 1rem;
  display: flex;
}

.sidenav .sidenav-navs-nav-button-chevron > svg {
  height: .75rem;
}

.sidenav .sidenav-navs-nav-button-chevron > svg path {
  fill: #0c042e;
}

.sidenav .ghost_button {
  text-align: left;
  text-transform: uppercase;
  background-color: #0000;
  font-weight: 700;
  text-decoration: none;
}

.sidenav .sidenav-navs-nav-list {
  height: 0;
  margin-top: .563rem;
  padding-left: 1.333rem;
  overflow-y: hidden;
}

.sidenav .sidenav-navs-nav-list-item {
  color: #858196;
}

.sidenav .sidenav-navs-nav-list-item:hover {
  text-decoration: underline;
}

.sidenav .sidenav-navs-nav-list-item + .sidenav-navs-nav-list-item {
  margin-top: .563rem;
}

.text_input {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  width: 100%;
  max-width: 400px;
  display: flex;
  position: relative;
}

.text_input .text_input-label {
  font-size: .667rem;
  font-weight: 600;
}

.text_input .text_input-helper {
  color: #8ca4b5;
  font-size: .667rem;
}

.text_input .text_input-input {
  appearance: none;
  color: #0c042e;
  background-color: #f9fbfc;
  border: 1px solid #8ca4b5;
  border-radius: 50px;
  outline: none;
  width: 100%;
  min-width: 200px;
  margin-top: 4px;
  padding: .667rem 1rem;
  font-size: 1rem;
  transition: border-color .1s;
  display: block;
}

.text_input .text_input-input[readonly] {
  border-color: #ededed;
}

.text_input .text_input-input[readonly]:focus {
  border-color: #ededed;
}

.text_input .text_input-input.text_input-input_validation {
  border-color: #fe0000;
}

.text_input .text_input-input::placeholder {
  color: #8ca4b5;
}

.text_input .text_input-input:focus {
  border-color: #0c042e;
}

.text_input .text_input-forgot_link {
  color: #858196;
  font-size: .667rem;
  text-decoration: underline;
  position: absolute;
  top: 0;
  right: 1rem;
}

.text_input .text_input-validation {
  color: #fe0000;
  padding-top: 2px;
  font-size: .667rem;
  position: relative;
}

.text_input .text_input-validation:before {
  content: "*";
}

.textarea {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  width: 100%;
  max-width: 400px;
  display: flex;
  position: relative;
}

.textarea .textarea-label {
  padding: 0 1rem;
  font-size: .667rem;
  font-weight: 600;
}

.textarea .textarea-helper {
  color: #8ca4b5;
  padding: 0 1rem;
  font-size: .667rem;
}

.textarea .textarea-input {
  appearance: none;
  color: #0c042e;
  resize: none;
  background-color: #f9fbfc;
  border: 1px solid #8ca4b5;
  border-radius: 20px;
  outline: none;
  width: 100%;
  min-width: 200px;
  margin-top: 4px;
  padding: .667rem 1rem;
  font-size: 1rem;
  transition: border-color .1s;
  display: block;
  overflow: auto;
}

.textarea .textarea-input[readonly] {
  border-color: #ededed;
}

.textarea .textarea-input[readonly]:focus {
  border-color: #ededed;
}

.textarea .textarea-input::placeholder {
  color: #8ca4b5;
}

.textarea .textarea-input:focus {
  border-color: #0c042e;
}

.textarea .textarea-validation {
  color: #fe0000;
  padding: 2px 1rem 0;
  font-size: .667rem;
  position: relative;
}

.textarea .textarea-validation:before {
  content: "*";
}

.undo_invite {
  box-shadow: var(--shadow-3);
  z-index: 10000;
  background-color: #07021a;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 360px;
  padding: 1rem 1.333rem;
  display: flex;
  position: fixed;
  bottom: 1.777rem;
  left: 1.333rem;
  overflow: hidden;
}

@media (width <= 1024px) {
  .undo_invite {
    padding: 1rem .333rem 1rem 1.333rem;
  }
}

@media (width <= 640px) {
  .undo_invite {
    border-radius: 0;
    padding: 1rem 0 1rem 1rem;
    bottom: 0;
    left: 0;
  }
}

.undo_invite:after {
  content: "";
  background-color: #4edfab;
  width: 100%;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
}

.undo_invite .undo_invite-message {
  color: #fff;
}

.undo_invite .undo_invite-actions {
  align-items: center;
  display: flex;
}

.undo_invite .undo_invite-actions .button {
  color: #fff;
  background-color: #858196;
  min-width: auto;
  font-size: .75rem;
}

.undo_invite .undo_invite-actions-close {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 1.777rem;
  margin-left: 1rem;
  display: flex;
}

@media (width <= 1024px) {
  .undo_invite .undo_invite-actions-close {
    width: 3.157rem;
  }
}

.undo_invite .undo_invite-actions-close > svg {
  fill: #858196;
  width: .75rem;
  height: .75rem;
}

.undo_invite .undo_invite-actions-close:hover svg {
  fill: #fff;
}

.about .about-splash {
  color: #fff;
  padding: calc(var(--header-height)  + 3.375rem) 1.5rem 3.375rem;
  background: linear-gradient(135deg, #0c042e 0%, #003564 25%, #72d9ff 100%);
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-height: 80vh;
  display: flex;
  position: relative;
}

@media (width <= 640px) {
  .about .about-splash {
    padding: calc(var(--header-height)  + 3.375rem) 1rem 3.375rem;
  }
}

.about .about-splash:after {
  content: "";
  background: url("auth-bg.6f895285.svg") 100vw 0 / cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform: scaleX(-1);
}

.about .about-splash-container {
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1080px;
  margin: auto;
  display: flex;
  position: relative;
}

@media (width <= 860px) {
  .about .about-splash-container {
    flex-direction: column;
  }
}

.about .about-splash-container > * {
  flex: 1;
}

.about .about-splash-container > * + * {
  margin-left: 2.25rem;
}

@media (width <= 860px) {
  .about .about-splash-container > * + * {
    margin-top: 1.5rem;
    margin-left: 0;
  }
}

.about .about-splash-content > h1 {
  text-transform: none;
  margin-bottom: 1rem;
}

.about .about-splash-content > p + p {
  margin-top: .444rem;
}

.about .about-splash-image > img {
  width: 100%;
  height: auto;
}

.about .about-story {
  padding: 5.053rem 1.5rem;
}

@media (width <= 640px) {
  .about .about-story {
    padding: 5.053rem 1rem;
  }
}

.about .about-story-container {
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1080px;
  margin: auto;
  display: flex;
  position: relative;
}

@media (width <= 860px) {
  .about .about-story-container {
    flex-direction: column;
  }
}

.about .about-story-container > * {
  flex: 1;
}

.about .about-story-container > * + * {
  margin-left: 2.25rem;
}

@media (width <= 860px) {
  .about .about-story-container > * + * {
    margin-top: 1.5rem;
    margin-left: 0;
  }
}

.about .about-story-content > h2 {
  text-transform: none;
  margin-bottom: 1rem;
  font-size: 2.369rem;
}

.about .about-story-content > p + p {
  margin-top: .444rem;
}

.about .about-story-content > .button {
  margin-top: 1.5rem;
}

.about .about-story-image > img {
  width: 100%;
  height: auto;
}

.about .about-comments {
  background-color: #e9edf2;
  padding: 5.053rem 1.5rem;
}

@media (width <= 640px) {
  .about .about-comments {
    padding: 5.053rem 1rem;
  }
}

.about .about-comments-container {
  text-align: center;
  width: 100%;
  max-width: 1080px;
  margin: auto;
}

.about .about-comments-list {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2.25rem;
  display: flex;
}

@media (width <= 860px) {
  .about .about-comments-list {
    flex-direction: column;
  }
}

.about .about-comments-list > * {
  flex: 1;
}

.about .about-comments-list > * + * {
  margin-left: 2.25rem;
}

@media (width <= 860px) {
  .about .about-comments-list > * + * {
    margin-top: 1.5rem;
    margin-left: 0;
  }
}

.about .about-comments-list-comment {
  max-width: 320px;
}

.about .about-comments-list-comment-name {
  font-weight: 600;
}

.about .about-comments-list-comment-text {
  font-size: .85rem;
}

.authentication {
  z-index: 1;
  background: #f2f2f2;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 0 .667rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.authentication:after {
  content: "";
  z-index: -1;
  background: url("auth-bg.6f895285.svg") 0 100% / cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

.authentication.signup {
  background: linear-gradient(to right, #003564 0% 25%, #049b72 100%);
}

.authentication.signup:after {
  background-position: 100vw 0;
  transform: scaleX(-1);
}

@media (width < 768px) {
  .authentication {
    background: #fff;
  }
}

.authentication .authentication-logo {
  height: 2.25rem;
  margin-top: 5.063rem;
}

@media (width < 768px) {
  .authentication .authentication-logo {
    margin-top: 1.5rem;
  }
}

.authentication .contained_form {
  margin-top: 5.063rem;
}

@media (width < 768px) {
  .authentication .contained_form {
    margin-top: 2.25rem;
  }
}

.authentication .authentication-footer {
  margin-top: auto;
  padding: 5.063rem 0 1rem;
}

@media (width < 768px) {
  .authentication .authentication-footer {
    padding: 3.375rem 0 1rem;
  }
}

.authentication.signup .authentication-footer {
  color: #fff;
}

.authentication .authentication-footer-list {
  display: flex;
}

.authentication .authentication-footer-list li {
  text-decoration: underline;
}

@media (width < 768px) {
  .authentication .authentication-footer-list li {
    font-size: .667rem;
  }
}

.authentication .authentication-footer-list li + li {
  margin-left: 2.25rem;
}

@media (width < 768px) {
  .authentication .authentication-footer-list li + li {
    margin-left: 1.5rem;
  }
}

.contacts {
  background: linear-gradient(135deg, #0c042e 0%, #003564 100%);
  width: 100%;
  min-height: 100vh;
  position: relative;
}

.contacts:after {
  content: "";
  background: url("auth-bg.6f895285.svg") 100vw 0 / cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform: scaleX(-1);
}

.contacts .contacts-content {
  padding: calc(var(--header-height)  + 5.063rem) 1rem 7.594rem;
  z-index: 1;
  min-height: 74.5vh;
  position: relative;
}

.contacts .contacts-content-container {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1080px;
  margin: auto;
  display: flex;
}

.contacts .contacts-content-container > * {
  flex: 1;
}

@media (width <= 860px) {
  .contacts .contacts-content-container {
    flex-direction: column;
    align-items: center;
  }
}

.contacts .contacts-content-text {
  color: #fff;
  margin-top: 3.375rem;
}

@media (width <= 860px) {
  .contacts .contacts-content-text {
    margin-top: 0;
  }
}

.contacts .contacts-content-text > h1 {
  text-transform: none;
  margin-bottom: 1.5rem;
  font-size: 3.157rem;
}

.contacts .contacts-content-text > p + p {
  margin-top: .444rem;
}

.contacts .contacts-content-form_card {
  box-shadow: var(--shadow-3);
  background-color: #fff;
  border-radius: 20px;
  margin-left: 3.375rem;
  padding: 1.25rem;
}

@media (width <= 860px) {
  .contacts .contacts-content-form_card {
    width: 100%;
    max-width: 540px;
    margin-top: 2.25rem;
    margin-left: 0;
  }
}

.contacts .contacts-content-form_card > h2 {
  border-bottom: 1px solid #003564;
  padding-bottom: .444rem;
}

.contacts .contacts-content-form_card-form {
  margin-top: 1rem;
}

.contacts .contacts-content-form_card-form > * + * {
  margin-top: .75rem;
}

.contacts .contacts-content-form_card-form > .button {
  margin-top: 1.5rem;
}

.contacts .contacts-content-form_card-form-row {
  display: flex;
}

@media (width <= 860px) {
  .contacts .contacts-content-form_card-form-row {
    flex-direction: column;
  }
}

.contacts .contacts-content-form_card-form-row > * {
  max-width: none;
}

.contacts .contacts-content-form_card-form-row > * + * {
  margin-left: 1rem;
}

@media (width <= 860px) {
  .contacts .contacts-content-form_card-form-row > * + * {
    margin-top: .75rem;
    margin-left: 0;
  }
}

.error .error-container {
  text-align: center;
  width: 100%;
  max-width: 560px;
  min-height: 100vh;
  margin: auto;
  padding: 5.053rem 1.5rem;
  position: relative;
}

.error .error-logo {
  height: 3.375rem;
}

.error .error-title {
  margin-top: 3.375rem;
}

.error .error-subtitle {
  margin-top: 1.5rem;
  font-size: 1.333rem;
}

.error .error-info {
  color: #8790a2;
  margin-top: 1rem;
}

.error .error-info > a {
  color: initial;
  text-decoration: underline;
}

.dashboard .dashboard-banner {
  z-index: calc(var(--z-header)  - 1);
  position: relative;
}

.dashboard .dashboard-board {
  padding: calc(var(--header-height)  + 3.375rem) 1.5rem 5.063rem;
  min-height: 100vh;
  position: relative;
}

@media (width <= 768px) {
  .dashboard .dashboard-board {
    padding: calc(var(--header-height)  + 2.25rem) 1.5rem 3.375rem;
  }
}

@media (width <= 610px) {
  .dashboard .dashboard-board {
    padding: calc(var(--header-height)  + 2.25rem) 1rem 3.375rem;
  }
}

.dashboard .dashboard-board:after {
  content: "";
  z-index: -1;
  background: url("auth-bg.6f895285.svg") 0 100% / cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.dashboard .dashboard-board-container {
  width: 100%;
  max-width: 1600px;
  margin: auto;
  display: flex;
}

@media (width <= 1630px) {
  .dashboard .dashboard-board-container {
    max-width: 1303px;
  }
}

@media (width <= 768px) {
  .dashboard .dashboard-board-container {
    flex-direction: column;
  }
}

.dashboard .battery {
  position: sticky;
  top: 1rem;
}

@media (width <= 768px) {
  .dashboard .battery {
    position: static;
  }
}

.dashboard .dashboard-board-info {
  padding-left: 2.25rem;
}

@media (width <= 768px) {
  .dashboard .dashboard-board-info {
    margin-top: 2.25rem;
    padding-left: 0;
  }
}

.dashboard .dashboard-board-info-benefits-container {
  box-shadow: var(--shadow-2);
  background-color: #fff;
  border-radius: 25px;
  flex-wrap: wrap;
  margin-top: 1rem;
  padding: .5rem;
  display: flex;
}

.dashboard .dashboard-board-info-benefits-container > .reward_card {
  flex-shrink: 0;
  margin: .5rem;
}

@media (width <= 1372px) {
  .dashboard .dashboard-board-info-benefits-container > .reward_card {
    width: calc(33% - 1rem);
    min-width: 240px;
    max-width: none;
  }
}

@media (width <= 1115px) {
  .dashboard .dashboard-board-info-benefits-container > .reward_card {
    width: calc(50% - 1rem);
  }
}

@media (width <= 880px) {
  .dashboard .dashboard-board-info-benefits-container > .reward_card {
    width: calc(100% - 1rem);
  }
}

@media (width <= 768px) {
  .dashboard .dashboard-board-info-benefits-container > .reward_card {
    width: calc(50% - 1rem);
  }
}

@media (width <= 610px) {
  .dashboard .dashboard-board-info-benefits-container > .reward_card {
    width: calc(100% - 1rem);
  }
}

.dashboard .dashboard-board-info-contents {
  box-shadow: var(--shadow-2);
  background-color: #fff;
  border-radius: 25px;
  flex-wrap: wrap;
  margin-top: 1rem;
  padding: 1.5rem;
  display: flex;
}

.dashboard .dashboard-board-info-activity_history {
  margin-top: 2.25rem;
}

.dashboard .dashboard-board-info-activity_history-container {
  box-shadow: var(--shadow-2);
  background-color: #fff;
  border-radius: 25px;
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
}

@media (width <= 1410px) {
  .dashboard .project_ad .project_ad-container {
    max-width: 960px;
  }
}

.dashboard .dashboard-project_pledges {
  background-color: #f7f7f7;
  padding: 5.063rem 1.5rem;
}

@media (width <= 768px) {
  .dashboard .dashboard-project_pledges {
    padding: 3.375rem 1.5rem;
  }
}

@media (width <= 610px) {
  .dashboard .dashboard-project_pledges {
    padding: 3.375rem 1rem;
  }
}

.dashboard .dashboard-project_pledges-container {
  align-items: flex-start;
  width: 100%;
  max-width: 1340px;
  margin: auto;
  display: flex;
}

@media (width <= 1410px) {
  .dashboard .dashboard-project_pledges-container {
    max-width: 960px;
  }
}

@media (width <= 1080px) {
  .dashboard .dashboard-project_pledges-container {
    flex-direction: column;
  }
}

.dashboard .dashboard-project_pledges-container-info {
  flex: 1;
  width: 100%;
  max-width: 380px;
  margin-right: 1.5rem;
  position: sticky;
  top: 1rem;
}

@media (width <= 1080px) {
  .dashboard .dashboard-project_pledges-container-info {
    text-align: center;
    max-width: none;
    margin-right: 0;
    position: static;
  }
}

.dashboard .dashboard-project_pledges-container-info > * + * {
  margin-top: 1.5rem;
}

.dashboard .dashboard-project_pledges-container-info sub {
  margin-top: .75rem;
}

.dashboard .dashboard-project_pledges-container-pledges {
  flex-wrap: wrap;
  flex: 2;
  margin: -.5rem;
  display: flex;
}

@media (width <= 1080px) {
  .dashboard .dashboard-project_pledges-container-pledges {
    justify-content: center;
    margin-top: 1.5rem;
  }
}

.friends_landing {
  background-color: #e5f3f9;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.friends_landing .header {
  box-shadow: none;
  background: none;
}

.friends_landing .header_default .header-nav-list-login_link svg path {
  fill: #0c042e;
}

.friends_landing .project_ad-container {
  padding: 16.8vh 0 5.063rem;
}

@media (width < 960px) {
  .friends_landing .project_ad-container {
    padding: 11vh 0 5.063rem;
  }
}

.friends_landing .project_ad-content {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

@media (width < 960px) {
  .friends_landing .project_ad-content h2 {
    order: 1;
  }

  .friends_landing .project_ad-content .project_ad-image.mobile {
    order: 2;
  }

  .friends_landing .project_ad-content .button {
    order: 3;
  }

  .friends_landing .project_ad-content p {
    order: 4;
  }
}

.friends_landing .project_ad.friends .project_ad-image {
  display: block;
}

@media (width < 960px) {
  .friends_landing .project_ad.friends .project_ad-image {
    display: none;
  }
}

.friends_landing .project_ad.friends .project_ad-image.mobile {
  display: none;
}

@media (width < 960px) {
  .friends_landing .project_ad.friends .project_ad-image.mobile {
    display: block;
  }
}

.friends_landing .footer {
  margin-top: auto;
}

.how_it_works {
  padding: var(--header-height) 1rem 0;
  background-color: #f2f2f2;
}

.how_it_works .how_it_works-container {
  grid-column-gap: 1.333rem;
  grid-template-columns: minmax(180px, 1fr) 5fr;
  width: 100%;
  max-width: 1600px;
  margin: auto;
  display: grid;
}

@media (width < 768px) {
  .how_it_works .how_it_works-container {
    display: block;
  }
}

.how_it_works .how_it_works-splash {
  color: #fff;
  z-index: 1;
  background: linear-gradient(135deg, #0c042e 5%, #003564 55%, #05ceac 100%);
  width: 100%;
  padding: 5.61rem 1rem;
  position: relative;
}

.how_it_works .how_it_works-splash:after {
  content: "";
  z-index: -1;
  background: url("auth-bg.6f895285.svg") 100% 0 / cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform: scaleX(-1);
}

.how_it_works .how_it_works-splash-container {
  max-width: 1100px;
  margin: auto;
}

.how_it_works .how_it_works-splash-container > h2 {
  text-transform: none;
  font-size: 2.25rem;
}

.how_it_works .how_it_works-splash-content {
  margin-top: 1rem;
  font-size: 1.25rem;
}

.how_it_works .how_it_works-splash-content > * + * {
  margin-top: .444rem;
}

.how_it_works .how_it_works-main {
  padding: 3.375rem 1rem;
}

.how_it_works .how_it_works-main-container {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.how_it_works .how_it_works-main-container > * + * {
  margin-top: 3.375rem;
}

.how_it_works .how_it_works-main-article > h2 {
  text-transform: none;
  font-size: 2.25rem;
}

.how_it_works .how_it_works-main-article-content {
  margin-top: 1.5rem;
}

.how_it_works .how_it_works-main-article-content > * + * {
  margin-top: .444rem;
}

.how_it_works .how_it_works-main-article-content > h3 {
  text-transform: none;
  margin-top: 1rem;
  font-size: 1.5rem;
}

.how_it_works .how_it_works-main-article-content .button {
  margin-top: 1rem;
}

.how_it_works .how_it_works-main-article-content figure {
  justify-content: center;
  margin: 1.5rem 0;
  display: flex;
}

.how_it_works .how_it_works-main-article-content img {
  width: 100%;
  max-width: 500px;
  height: auto;
}

.how_it_works .how_it_works-main-article-content-info {
  border: 3px solid #0c042e;
  border-radius: 14px;
  margin: 1rem 0;
  padding: 1.25rem;
  font-size: .85rem;
  font-style: italic;
}

.how_it_works .how_it_works-main-article-content-levels {
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem 0;
  display: inline-flex;
}

@media (width <= 580px) {
  .how_it_works .how_it_works-main-article-content-levels {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.how_it_works .how_it_works-main-article-content-levels-container {
  justify-content: flex-start;
  display: inline-flex;
}

@media (width <= 580px) {
  .how_it_works .how_it_works-main-article-content-levels-container {
    flex-direction: column;
  }
}

.how_it_works .how_it_works-main-article-content-levels-level {
  box-shadow: var(--shadow-2);
  text-align: center;
  background-color: #fff;
  border-radius: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 156px;
  height: 156px;
  margin: .75rem;
  padding: 1.25rem 1rem;
  display: flex;
  position: relative;
}

.how_it_works .how_it_works-main-article-content-levels-level:after {
  content: "";
  border-top: .75rem solid #0000;
  border-bottom: .75rem solid #0000;
  border-left: .75rem solid #0c042e;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: calc(100% + .444rem);
  transform: translateY(-50%);
}

@media (width <= 580px) {
  .how_it_works .how_it_works-main-article-content-levels-level:after {
    border: .75rem solid #0000;
    border-top-color: #0c042e;
    border-bottom: none;
    top: calc(100% + .444rem);
    left: 50%;
    transform: translateX(-50%);
  }
}

.how_it_works .how_it_works-main-article-content-levels-level > * + * {
  margin-top: 1rem;
}

.how_it_works .how_it_works-main-article-content-levels-level > .reward_icon {
  width: 60px;
  height: 60px;
}

.how_it_works .how_it_works-main-article-content-levels-level > .reward_icon.level_zero {
  background-color: #930062;
}

.how_it_works .how_it_works-main-article-content-levels-level > .reward_icon .reward_icon-icon {
  width: 40px;
  height: 40px;
}

.how_it_works .how_it_works-main-article-content-levels-level > h4 {
  text-transform: uppercase;
  line-height: 1;
}

.how_it_works .how_it_works-main-article-content-levels-container:last-of-type .how_it_works-main-article-content-levels-level:last-of-type:after {
  content: none;
}

@media (581px <= width <= 1112px) {
  .how_it_works .how_it_works-main-article-content-levels-container:first-of-type .how_it_works-main-article-content-levels-level:last-of-type:after {
    border: .75rem solid #0000;
    border-top-color: #0c042e;
    border-bottom: none;
    top: calc(100% + .444rem);
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (581px <= width <= 1112px) {
  .how_it_works .how_it_works-main-article-content-levels-container:last-of-type .how_it_works-main-article-content-levels-level:after {
    border: .75rem solid #0000;
    border-left: none;
    border-right-color: #0c042e;
  }
}

@media (581px <= width <= 1112px) {
  .how_it_works .how_it_works-main-article-content-levels-container:last-of-type .how_it_works-main-article-content-levels-level:first-child {
    order: 3;
  }
}

@media (581px <= width <= 1112px) {
  .how_it_works .how_it_works-main-article-content-levels-container:last-of-type .how_it_works-main-article-content-levels-level:first-child:after {
    content: none;
  }
}

@media (581px <= width <= 1112px) {
  .how_it_works .how_it_works-main-article-content-levels-container:last-of-type .how_it_works-main-article-content-levels-level:nth-child(2) {
    order: 2;
  }
}

@media (581px <= width <= 1112px) {
  .how_it_works .how_it_works-main-article-content-levels-container:last-of-type .how_it_works-main-article-content-levels-level:nth-child(3):after {
    content: "";
  }
}

.how_it_works .how_it_works-main-article-content-statuses {
  margin-top: 1rem;
}

.how_it_works .how_it_works-main-article-content-statuses-status {
  align-items: flex-start;
  margin-top: .563rem;
  display: flex;
}

.how_it_works .how_it_works-main-article-content-statuses-status > p {
  padding-top: .422rem;
}

.how_it_works .how_it_works-main-article-content-statuses-status-icon {
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 2.369rem;
  height: 2.369rem;
  margin-right: .75rem;
  display: flex;
  position: relative;
}

.how_it_works .how_it_works-main-article-content-statuses-status-icon:after {
  content: "";
  border-radius: 50%;
  width: 1.777rem;
  height: 1.777rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.how_it_works .how_it_works-main-article-content-statuses-status-icon.sent {
  background: #f4840033;
}

.how_it_works .how_it_works-main-article-content-statuses-status-icon.sent:after {
  background: #f48400;
}

.how_it_works .how_it_works-main-article-content-statuses-status-icon.clicked {
  background: #4685ff33;
}

.how_it_works .how_it_works-main-article-content-statuses-status-icon.clicked:after {
  background: #4685ff;
}

.how_it_works .how_it_works-main-article-content-statuses-status-icon.discount {
  background: #00b92b33;
}

.how_it_works .how_it_works-main-article-content-statuses-status-icon.discount:after {
  background: #00b92b;
}

.how_it_works .how_it_works-main-article-content-statuses-status-icon.no_discount {
  background: #fe000033;
}

.how_it_works .how_it_works-main-article-content-statuses-status-icon.no_discount:after {
  background: #fe0000;
}

.how_it_works .how_it_works-main-article-content-statuses-status-icon svg {
  z-index: 1;
  width: auto;
  height: 1.25rem;
  position: relative;
}

.landing {
  background-color: #f2f2f2;
  width: 100%;
  min-height: 100vh;
}

.landing-splash {
  color: #fff;
  padding: calc(var(--header-height)  + 5.063rem) 1rem 7.594rem;
  z-index: 1;
  background: linear-gradient(to right, #003564 0% 25%, #049b72 100%);
  width: 100%;
  position: relative;
}

@media (width <= 768px) {
  .landing-splash {
    padding: calc(var(--header-height)  + 2.25rem) 1rem 5.063rem;
  }
}

.landing-splash:after {
  content: "";
  z-index: -1;
  background: url("auth-bg.6f895285.svg") 100vw 0 / cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform: scaleX(-1);
}

.landing-splash-container {
  justify-content: space-between;
  width: 100%;
  max-width: 1080px;
  margin: auto;
  display: flex;
}

@media (width <= 768px) {
  .landing-splash-container {
    flex-direction: column;
  }
}

.landing-splash-content {
  flex-grow: 1;
  max-width: 640px;
  margin-right: 5.063rem;
}

@media (width <= 768px) {
  .landing-splash-content {
    margin-right: 0;
  }
}

.landing-splash-content .landing-splash-content-battery {
  display: none;
}

@media (width <= 768px) {
  .landing-splash-content .landing-splash-content-battery {
    display: block;
  }
}

.anonymous .landing-splash-content {
  margin-top: 5.063rem;
}

@media (width <= 768px) {
  .anonymous .landing-splash-content {
    margin-top: 0;
  }
}

.landing-splash-content-message h1 {
  margin-top: 1rem;
  font-size: 2.369rem;
}

.landing-splash-content-message h1 > span {
  color: #f4bb00;
}

@media (width <= 768px) {
  .landing-splash-content-message h1 > br {
    display: none;
  }
}

.landing-splash-content-message > .button {
  margin-top: 1.333rem;
}

.landing-splash-content-message-caption {
  margin-top: 1.5rem;
}

.landing-splash-content-message-caption > p + p {
  margin-top: .667rem;
}

.landing-splash-content-rewards {
  margin-top: 3.375rem;
}

.landing-splash-content-rewards .landing_rewards {
  margin-top: 1rem;
}

.landing-splash-content-rewards > .button {
  margin-top: 1.5rem;
}

.landing-splash-content-battery {
  flex-grow: 0;
}

@media (width <= 768px) {
  .landing-splash-content-battery {
    margin-top: 2.25rem;
  }
}

@media (width <= 768px) {
  .landing-splash-content + .landing-splash-content-battery {
    display: none;
  }
}

.landing-splash-content-battery-temp {
  background: #fff;
  width: 100px;
  height: 50vh;
}

.landing-info-container {
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 7.594rem 1rem;
  display: flex;
}

@media (width <= 768px) {
  .landing-info-container {
    padding: 3.375rem 1rem;
  }
}

.landing-info-container > * + * {
  margin-top: 2.25rem;
}

.landing-info-cards {
  align-items: flex-start;
  display: flex;
}

@media (width <= 768px) {
  .landing-info-cards {
    flex-direction: column;
  }
}

.landing-info-cards > * + * {
  margin-left: 2.25rem;
}

@media (width <= 960px) {
  .landing-info-cards > * + * {
    margin-left: 1rem;
  }
}

@media (width <= 768px) {
  .landing-info-cards > * + * {
    margin-top: 1rem;
    margin-left: 0;
  }
}

.landing-benefits {
  padding: 5.063rem 1rem 7.594rem;
}

@media (width <= 768px) {
  .landing-benefits {
    padding: 5.063rem 1rem;
  }
}

.landing-benefits-container {
  max-width: 1080px;
  margin: auto;
}

.landing-benefits-container > .button {
  margin-top: 1.5rem;
}

.landing-benefits-container-list {
  box-shadow: var(--shadow-2);
  background-color: #fff;
  border-radius: 25px;
  flex-wrap: wrap;
  margin-top: 1rem;
  padding: .5rem;
  display: flex;
}

.landing-benefits-container-list > .reward_card {
  flex-shrink: 0;
  margin: .5rem;
}

@media (width <= 1372px) {
  .landing-benefits-container-list > .reward_card {
    width: calc(33% - 1rem);
    min-width: 240px;
    max-width: none;
  }
}

@media (width <= 1115px) {
  .landing-benefits-container-list > .reward_card {
    width: calc(50% - 1rem);
  }
}

@media (width <= 768px) {
  .landing-benefits-container-list > .reward_card {
    width: calc(50% - 1rem);
  }
}

@media (width <= 610px) {
  .landing-benefits-container-list > .reward_card {
    width: calc(100% - 1rem);
  }
}

@media (width <= 768px) {
  .news .button {
    font-size: .85rem;
  }
}

.news .news-splash {
  padding: calc(var(--header-height)  + 3.375rem) 1.5rem 3.375rem;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 80vh;
  display: flex;
}

.news .news-splash > * + * {
  margin-top: 2.25rem;
}

@media (width <= 768px) {
  .news .news-splash > * + * {
    margin-top: 1.5rem;
  }
}

.news .news-splash-logo {
  width: 100%;
  max-width: 768px;
}

.news .news-splash-logo > img {
  width: 100%;
  height: auto;
}

.news .news-project {
  background-color: #edf0f2;
}

.news .news-project-container {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 5.053rem 1.5rem;
  display: flex;
}

@media (width <= 960px) {
  .news .news-project-container {
    flex-direction: column;
  }
}

@media (width <= 768px) {
  .news .news-project-container {
    padding: 3.375rem 1rem;
  }
}

.news .news-project-container > * {
  flex: 1;
}

.news .news-project-content > h1 {
  margin-bottom: 1.5rem;
}

.news .news-project-content > p + p {
  margin-top: .444rem;
}

.news .news-project-image {
  margin-left: 2.25rem;
}

@media (width <= 960px) {
  .news .news-project-image {
    margin-top: 1.5rem;
    margin-left: 0;
  }
}

.news .news-project-image > img {
  width: 100%;
  height: auto;
}

.news .news-pledges-container {
  text-align: center;
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 5.053rem 1.5rem;
}

@media (width <= 768px) {
  .news .news-pledges-container {
    padding: 3.375rem 1rem;
  }
}

.news .news-pledges-container > h2 {
  margin-bottom: .444rem;
  font-size: 2.369rem;
}

.news .news-pledges-list {
  text-align: left;
  width: 100%;
  max-width: 960px;
  margin: 5.053rem auto;
}

@media (width <= 768px) {
  .news .news-pledges-list {
    margin: 3.375rem auto;
  }
}

.news .news-pledges-list > * + * {
  margin-top: 3.375rem;
}

.news .news-pledges-list-pledge {
  display: flex;
}

@media (width <= 768px) {
  .news .news-pledges-list-pledge {
    flex-direction: column;
  }
}

.news .news-pledges-list-pledge:nth-child(2n) .news-pledges-list-pledge-description {
  order: 1;
  margin-left: 2.25rem;
}

@media (width <= 768px) {
  .news .news-pledges-list-pledge:nth-child(2n) .news-pledges-list-pledge-description {
    margin-left: 0;
  }
}

.news .news-pledges-list-pledge:nth-child(odd) .news-pledges-list-pledge-image {
  margin-left: 2.25rem;
}

@media (width <= 768px) {
  .news .news-pledges-list-pledge:nth-child(odd) .news-pledges-list-pledge-image {
    margin-left: 0;
  }
}

.news .news-pledges-list-pledge > * {
  flex: 1;
}

@media (width <= 768px) {
  .news .news-pledges-list-pledge-description {
    order: 2;
  }
}

.news .news-pledges-list-pledge-description > h3 {
  font-size: 1.5rem;
}

.news .news-pledges-list-pledge-description > h4 {
  text-transform: uppercase;
}

.news .news-pledges-list-pledge-description > * + * {
  margin-top: .444rem;
}

.news .news-pledges-list-pledge-description-boost {
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: #0c042e;
  border-radius: 7px;
  align-items: center;
  width: max-content;
  margin-top: .296rem;
  padding: .296rem .667rem;
  font-size: .667rem;
  font-weight: 700;
  display: flex;
}

.news .boost-battery {
  height: .75rem;
  margin-left: 6px;
}

@media (width <= 768px) {
  .news .news-pledges-list-pledge-image {
    margin-bottom: 1rem;
    margin-left: 0;
  }
}

.news .news-pledges-list-pledge-image > img {
  width: 100%;
  height: auto;
}

.policy .policy-content {
  padding: calc(var(--header-height)  + 3.375rem) 1.5rem 5.063rem;
  min-height: 100vh;
  position: relative;
}

.policy .policy-content:after {
  content: "";
  z-index: -1;
  background: url("auth-bg.6f895285.svg") 0 100% / cover fixed;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.policy .policy-content-container {
  margin-top: 2.25rem;
}

.policy .policy-content-container > * + * {
  margin-top: 1.5rem;
}

.settings {
  min-height: 100vh;
  position: relative;
}

.settings:after {
  content: "";
  z-index: -1;
  background: url("auth-bg.6f895285.svg") 0 100% / cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.settings .settings-board {
  padding: calc(var(--header-height)  + 3.375rem) 1.5rem 5.063rem;
  max-width: 1280px;
  margin: auto;
}

.settings .settings-board > * + * {
  margin-top: 1.5rem;
}

.friends_dashboard {
  padding: calc(var(--header-height)  + 3.375rem) 1.5rem 5.063rem;
  min-height: 100vh;
  position: relative;
}

@media (width <= 1024px) {
  .friends_dashboard {
    padding: calc(var(--header-height)  + 2.25rem) 1.5rem 3.375rem;
  }
}

@media (width < 768px) {
  .friends_dashboard {
    padding: calc(var(--header-height)  + 2.25rem) 1rem 3.375rem;
  }
}

.friends_dashboard:after {
  content: "";
  z-index: -1;
  background: url("auth-bg.6f895285.svg") 0 100% / cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.friends_dashboard .friends_dashboard-container {
  width: 100%;
  max-width: 1440px;
  margin: auto;
}

.friends_dashboard .friends_dashboard-container h1 {
  margin-left: 1.777rem;
}

@media (width < 768px) {
  .friends_dashboard .friends_dashboard-container h1 {
    margin-left: 0;
  }
}

.friends_dashboard .friends_dashboard-content {
  grid-column-gap: 3.157rem;
  grid-template-columns: 1fr 1fr;
  margin-top: 1.777rem;
  display: grid;
}

@media (width <= 1440px) {
  .friends_dashboard .friends_dashboard-content {
    grid-column-gap: 2.369rem;
  }
}

@media (width <= 1200px) {
  .friends_dashboard .friends_dashboard-content {
    grid-template-columns: 3fr 2fr;
  }
}

@media (width <= 1024px) {
  .friends_dashboard .friends_dashboard-content {
    grid-template-columns: 1fr;
  }
}

.friends_dashboard .friends_dashboard-content-boards {
  background: #ffffff80;
  border-radius: 1rem;
  padding: 1.777rem;
}

@media (width < 768px) {
  .friends_dashboard .friends_dashboard-content-boards {
    background: none;
    padding: 0;
  }
}

.friends_dashboard .friends_dashboard-content-boards > * + * {
  margin-top: 1.777rem;
}

.friends_dashboard .friends_dashboard-content-illustration {
  align-items: center;
  display: flex;
}

@media (width <= 1024px) {
  .friends_dashboard .friends_dashboard-content-illustration {
    display: none;
  }
}

.friends_dashboard .friends_dashboard-content-illustration > img {
  width: 100%;
  height: auto;
}



/*# sourceMappingURL=main.css.map */
