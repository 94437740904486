@custom-media --small (width < 768px);

.contained_form {
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: var(--shadow-2);
  display: flex;
  flex-direction: column;
  max-width: 622px;
  padding: 3.375rem;
  width: 100%;

  @media (--small) {
    max-width: none;
    padding: 2.25rem 1rem;
  }

  & h2 {
    @media (--small) {
      font-size: 1rem;
    }
  }

  & .contained_form-form {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 2.25rem;
    width: 100%;

    & > .text_input + .text_input {
      margin-top: 1rem;
    }

    & > .button {
      margin-top: 1.5rem;
    }
  }

  & .contained_form-message {
    text-align: center;

    & > * + * {
      margin-top: 1.5rem;
    }

    & .contained_form-message-heading {
      font-size: 1.25rem;
    }

    & .contained_form-message-accent {
      font-size: 1.25rem;
      font-weight: 600;
    }

    & .contained_form-message-content {
      font-size: 1rem;
    }
  }

  &.invalid h3 {
    color: #fe0000;
  }

  & > .ghost_button {
    margin-top: 1.5rem;
  }
}
