@custom-media --medium (width <= 1024px);
@custom-media --small (width <= 640px);

.undo_invite {
  align-items: center;
  background-color: #07021a;
  border-radius: 10px;
  bottom: 1.777rem;
  box-shadow: var(--shadow-3);
  display: flex;
  justify-content: space-between;
  left: 1.333rem;
  max-width: 360px;
  overflow: hidden;
  padding: 1rem 1.333rem;
  position: fixed;
  width: 100%;
  z-index: 10000;

  @media (--medium) {
    padding: 1rem 0.333rem 1rem 1.333rem;
  }

  @media (--small) {
    border-radius: 0;
    bottom: 0;
    left: 0;
    padding: 1rem 0 1rem 1rem;
  }

  &::after {
    background-color: #4edfab;
    content: "";
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  & .undo_invite-message {
    color: #fff;
  }

  & .undo_invite-actions {
    align-items: center;
    display: flex;

    & .button {
      background-color: #858196;
      color: #fff;
      font-size: 0.75rem;
      min-width: auto;
    }
  }

  & .undo_invite-actions-close {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 1.777rem;
    justify-content: center;
    margin-left: 1rem;

    @media (--medium) {
      width: 3.157rem;
    }

    & > svg {
      fill: #858196;
      height: 0.75rem;
      width: 0.75rem;
    }

    &:hover svg {
      fill: #fff;
    }
  }
}
