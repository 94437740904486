.error {
  & .error-container {
    margin: auto;
    max-width: 560px;
    min-height: 100vh;
    padding: 5.053rem 1.5rem;
    position: relative;
    text-align: center;
    width: 100%;
  }

  & .error-logo {
    height: 3.375rem;
  }

  & .error-title {
    margin-top: 3.375rem;
  }

  & .error-subtitle {
    font-size: 1.333rem;
    margin-top: 1.5rem;
  }

  & .error-info {
    color: #8790a2;
    margin-top: 1rem;

    & > a {
      color: initial;
      text-decoration: underline;
    }
  }
}
