.policy_card {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: var(--shadow-2);
  padding: 1rem 1.5rem 1.5rem;

  & > h2 {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 0.444rem;
    width: 100%;
  }

  &:not(:first-child) > h2 {
    text-transform: none;
  }

  & .policy_card-content {
    font-size: 0.75rem;
    padding-top: 1rem;

    & > * + * {
      margin-top: 0.444rem;
    }
  }
}
