@custom-media --small (width <= 860px);

.contacts {
  background: linear-gradient(135deg, #0c042e 0%, #003564 100%);
  min-height: 100vh;
  position: relative;
  width: 100%;

  &::after {
    background: url("../../assets/images/auth-bg.svg") 100vw 0 / cover;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleX(-1);
    width: 100%;
  }

  & .contacts-content {
    min-height: 74.5vh;
    padding: calc(var(--header-height) + 5.063rem) 1rem 7.594rem;
    position: relative;
    z-index: 1;
  }

  & .contacts-content-container {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: 1080px;
    width: 100%;

    & > * {
      flex: 1;
    }

    @media (--small) {
      align-items: center;
      flex-direction: column;
    }
  }

  & .contacts-content-text {
    color: #fff;
    margin-top: 3.375rem;

    @media (--small) {
      margin-top: 0;
    }

    & > h1 {
      font-size: 3.157rem;
      margin-bottom: 1.5rem;
      text-transform: none;
    }

    & > p + p {
      margin-top: 0.444rem;
    }
  }

  & .contacts-content-form_card {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: var(--shadow-3);
    margin-left: 3.375rem;
    padding: 1.25rem;

    @media (--small) {
      margin-left: 0;
      margin-top: 2.25rem;
      width: 100%;
      max-width: 540px;
    }

    & > h2 {
      border-bottom: 1px solid #003564;
      padding-bottom: 0.444rem;
    }
  }

  & .contacts-content-form_card-form {
    margin-top: 1rem;

    & > * + * {
      margin-top: 0.75rem;
    }

    & > .button {
      margin-top: 1.5rem;
    }
  }

  & .contacts-content-form_card-form-row {
    display: flex;

    @media (--small) {
      flex-direction: column;
    }

    & > * {
      max-width: none;
    }

    & > * + * {
      margin-left: 1rem;

      @media (--small) {
        margin-left: 0;
        margin-top: 0.75rem;
      }
    }
  }
}
