@custom-media --small (width <= 768px);

.activity_history {
  width: 100%;

  & .activity_history-purchase {
    display: flex;

    &:not(:last-of-type) {
      border-bottom: 2px solid #0c042e;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }
  }

  & .activity_history-purchase-image {
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    flex-shrink: 0;
    height: 92px;
    width: 92px;
  }

  & .activity_history-purchase-content {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    width: 100%;

    @media (--small) {
      flex-direction: column;
      padding-right: 0;
    }
  }

  & .activity_history-purchase-content-product {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  & .activity_history-purchase-content-product-description {
    margin-top: 0.132rem;

    @media (--small) {
      font-size: 0.75rem;
    }
  }

  & .activity_history-purchase-content-product-boost {
    align-items: center;
    background-color: #0c042e;
    border-radius: 7px;
    color: #fff;
    display: flex;
    font-size: 0.667rem;
    font-weight: 700;
    margin-top: 0.296rem;
    padding: 0.296rem 0.667rem;
    text-transform: uppercase;
  }

  & .boost-battery {
    height: 0.75rem;
    margin-left: 6px;
  }

  & .activity_history-purchase-content-purchase_info {
    font-size: 0.667rem;

    @media (--small) {
      margin-top: 0.444rem;
    }

    & > span {
      font-size: 0.444em;
      vertical-align: super;
    }
  }
}
