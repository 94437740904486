.settings {
  min-height: 100vh;
  position: relative;

  &::after {
    background: url("../../assets/images/auth-bg.svg") bottom left / cover;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  & .settings-board {
    margin: auto;
    max-width: 1280px;
    padding: calc(var(--header-height) + 3.375rem) 1.5rem 5.063rem;

    & > * + * {
      margin-top: 1.5rem;
    }
  }
}
