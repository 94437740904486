@custom-media --medium (width <= 860px);
@custom-media --small (width <= 640px);

.about {
  & .about-splash {
    align-items: center;
    background: linear-gradient(135deg, #0c042e 0%, #003564 25%, #72d9ff 100%);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: calc(var(--header-height) + 3.375rem) 1.5rem 3.375rem;
    position: relative;
    min-height: 80vh;

    @media (--small) {
      padding: calc(var(--header-height) + 3.375rem) 1rem 3.375rem;
    }

    &::after {
      background: url("../../assets/images/auth-bg.svg") 100vw 0 / cover;
      content: "";
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: scaleX(-1);
      width: 100%;
    }
  }

  & .about-splash-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: 1080px;
    position: relative;
    width: 100%;
    z-index: 1;

    @media (--medium) {
      flex-direction: column;
    }

    & > * {
      flex: 1;
    }

    & > * + * {
      margin-left: 2.25rem;

      @media (--medium) {
        margin-left: 0;
        margin-top: 1.5rem;
      }
    }
  }

  & .about-splash-content {
    & > h1 {
      margin-bottom: 1rem;
      text-transform: none;
    }

    & > p + p {
      margin-top: 0.444rem;
    }
  }

  & .about-splash-image {
    & > img {
      height: auto;
      width: 100%;
    }
  }

  & .about-story {
    padding: 5.053rem 1.5rem;

    @media (--small) {
      padding: 5.053rem 1rem;
    }
  }

  & .about-story-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: 1080px;
    position: relative;
    width: 100%;
    z-index: 1;

    @media (--medium) {
      flex-direction: column;
    }

    & > * {
      flex: 1;
    }

    & > * + * {
      margin-left: 2.25rem;

      @media (--medium) {
        margin-left: 0;
        margin-top: 1.5rem;
      }
    }
  }

  & .about-story-content {
    & > h2 {
      font-size: 2.369rem;
      margin-bottom: 1rem;
      text-transform: none;
    }

    & > p + p {
      margin-top: 0.444rem;
    }

    & > .button {
      margin-top: 1.5rem;
    }
  }

  & .about-story-image {
    & > img {
      height: auto;
      width: 100%;
    }
  }

  & .about-comments {
    background-color: #E9EDF2;
    padding: 5.053rem 1.5rem;

    @media (--small) {
      padding: 5.053rem 1rem;
    }
  }

  & .about-comments-container {
    margin: auto;
    max-width: 1080px;
    text-align: center;
    width: 100%;
  }

  & .about-comments-list {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 2.25rem;
    width: 100%;

    @media (--medium) {
      flex-direction: column;
    }

    & > * {
      flex: 1;
    }

    & > * + * {
      margin-left: 2.25rem;

      @media (--medium) {
        margin-left: 0;
        margin-top: 1.5rem;
      }
    }
  }

  & .about-comments-list-comment {
    max-width: 320px;
  }

  & .about-comments-list-comment-name {
    font-weight: 600;
  }

  & .about-comments-list-comment-text {
    font-size: 0.85rem;
  }
}
