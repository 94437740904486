@custom-media --board-xxlarge (width <= 1630px);
@custom-media --board-xlarge (width <= 1372px);
@custom-media --board-large (width <= 1115px);
@custom-media --board-medium (width <= 880px);
@custom-media --board-small (width <= 768px);
@custom-media --board-xsmall (width <= 610px);

@custom-media --project-large (width <= 1410px);
@custom-media --project-medium (width <= 1080px);
@custom-media --project-small (width <= 768px);
@custom-media --project-xsmall (width <= 610px);

.dashboard {
  & .dashboard-banner {
    position: relative;
    z-index: calc(var(--z-header) - 1);
  }

  & .dashboard-board {
    min-height: 100vh;
    padding: calc(var(--header-height) + 3.375rem) 1.5rem 5.063rem;
    position: relative;

    @media (--board-small) {
      padding: calc(var(--header-height) + 2.25rem) 1.5rem 3.375rem;
    }

    @media (--board-xsmall) {
      padding: calc(var(--header-height) + 2.25rem) 1rem 3.375rem;
    }

    &::after {
      background: url("../../assets/images/auth-bg.svg") bottom left / cover;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }

  & .dashboard-board-container {
    display: flex;
    margin: auto;
    max-width: 1600px;
    width: 100%;

    @media (--board-xxlarge) {
      max-width: 1303px;
    }

    @media (--board-small) {
      flex-direction: column;
    }
  }

  & .battery {
    position: sticky;
    top: 1rem;

    @media (--board-small) {
      position: static;
    }
  }

  & .dashboard-board-info {
    padding-left: 2.25rem;

    @media (--board-small) {
      padding-left: 0;
      margin-top: 2.25rem;
    }
  }

  & .dashboard-board-info-benefits-container {
    background-color: #fff;
    border-radius: 25px;
    box-shadow: var(--shadow-2);
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    padding: 0.5rem;

    & > .reward_card {
      flex-shrink: 0;
      margin: 0.5rem;

      @media (--board-xlarge) {
        min-width: 240px;
        max-width: none;
        width: calc(33% - 1rem);
      }

      @media (--board-large) {
        width: calc(50% - 1rem);
      }

      @media (--board-medium) {
        width: calc(100% - 1rem);
      }

      @media (--board-small) {
        width: calc(50% - 1rem);
      }

      @media (--board-xsmall) {
        width: calc(100% - 1rem);
      }
    }
  }

  & .dashboard-board-info-contents {
    background-color: #fff;
    border-radius: 25px;
    box-shadow: var(--shadow-2);
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    padding: 1.5rem;
  }

  & .dashboard-board-info-activity_history {
    margin-top: 2.25rem;
  }

  & .dashboard-board-info-activity_history-container {
    background-color: #fff;
    border-radius: 25px;
    box-shadow: var(--shadow-2);
    display: flex;
    margin-top: 1rem;
    padding: 1rem;
  }

  & .project_ad .project_ad-container {
    @media (--project-large) {
      max-width: 960px;
    }
  }

  & .dashboard-project_pledges {
    background-color: #f7f7f7;
    padding: 5.063rem 1.5rem;

    @media (--project-small) {
      padding: 3.375rem 1.5rem;
    }

    @media (--project-xsmall) {
      padding: 3.375rem 1rem;
    }
  }

  & .dashboard-project_pledges-container {
    align-items: flex-start;
    display: flex;
    margin: auto;
    max-width: 1340px;
    width: 100%;

    @media (--project-large) {
      max-width: 960px;
    }

    @media (--project-medium) {
      flex-direction: column;
    }
  }

  & .dashboard-project_pledges-container-info {
    flex: 1;
    margin-right: 1.5rem;
    max-width: 380px;
    position: sticky;
    top: 1rem;
    width: 100%;

    @media (--project-medium) {
      margin-right: 0;
      max-width: none;
      position: static;
      text-align: center;
    }

    & > * + * {
      margin-top: 1.5rem;
    }

    & sub {
      margin-top: 0.75rem;
    }
  }

  & .dashboard-project_pledges-container-pledges {
    display: flex;
    flex: 2;
    flex-wrap: wrap;
    margin: -0.5rem;

    @media (--project-medium) {
      justify-content: center;
      margin-top: 1.5rem;
    }
  }
}
