@custom-media --small (width < 960px);

.friends_landing {
  background-color: #e5f3f9;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  & .header {
    background: transparent;
    box-shadow: none;
  }

  & .header_default .header-nav-list-login_link svg path {
    fill: #0c042e;
  }

  & .project_ad-container {
    padding: 16.8vh 0 5.063rem;

    @media (--small) {
      padding: 11vh 0 5.063rem;
    }
  }

  & .project_ad-content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    @media (--small) {
      & h2 {
        order: 1;
      }

      & .project_ad-image.mobile {
        order: 2;
      }

      & .button {
        order: 3;
      }

      & p {
        order: 4;
      }
    }
  }

  & .project_ad.friends .project_ad-image {
    display: block;

    @media (--small) {
      display: none;
    }
  }

  & .project_ad.friends .project_ad-image.mobile {
    display: none;

    @media (--small) {
      display: block;
    }
  }

  & .footer {
    margin-top: auto;
  }
}
