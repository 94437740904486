.reward_icon {
  align-items: center;
  background-color: #8790a2;
  border-radius: 50%;
  box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25),
    0 3px 7px -3px rgba(0, 0, 0, 0.3);
  display: inline-flex;
  flex-shrink: 0;
  height: 91px;
  justify-content: center;
  width: 91px;

  &.is_reached {
    &.level_one {
      background-color: var(--level-one);
    }

    &.level_two {
      background-color: var(--level-two);
    }

    &.level_three {
      background-color: var(--level-three);
    }

    &.level_four {
      background-color: var(--level-four);
    }

    &.level_five {
      background-color: var(--level-five);
    }
  }

  & .reward_icon-icon {
    height: 61px;
    width: 61px;

    & svg {
      fill: #fff;
    }
  }
}
