@custom-media --medium (581px <= width <= 1112px);
@custom-media --small (width <= 580px);
@custom-media --mobile (width < 768px);

.how_it_works {
  background-color: #f2f2f2;
  padding: var(--header-height) 1rem 0;

  & .how_it_works-container {
    display: grid;
    grid-column-gap: 1.333rem;
    grid-template-columns: minmax(180px, 1fr) 5fr;
    margin: auto;
    max-width: 1600px;
    width: 100%;

    @media (--mobile) {
      display: block;
    }
  }

  & .how_it_works-splash {
    background: linear-gradient(135deg, #0c042e 5%, #003564 55%, #05ceac 100%);
    color: #fff;
    padding: 5.61rem 1rem;
    position: relative;
    width: 100%;
    z-index: 1;

    &::after {
      background: url("../../assets/images/auth-bg.svg") 100vw 0 / cover;
      background-position: top right;
      content: "";
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: scaleX(-1);
      width: 100%;
      z-index: -1;
    }
  }

  & .how_it_works-splash-container {
    margin: auto;
    max-width: 1100px;

    & > h2 {
      font-size: 2.25rem;
      text-transform: none;
    }
  }

  & .how_it_works-splash-content {
    font-size: 1.25rem;
    margin-top: 1rem;

    & > * + * {
      margin-top: 0.444rem;
    }
  }

  & .how_it_works-main {
    padding: 3.375rem 1rem;
  }

  & .how_it_works-main-container {
    margin: auto;
    max-width: 1100px;
    width: 100%;

    & > * + * {
      margin-top: 3.375rem;
    }
  }

  & .how_it_works-main-article {
    & > h2 {
      font-size: 2.25rem;
      text-transform: none;
    }
  }

  & .how_it_works-main-article-content {
    margin-top: 1.5rem;

    & > * + * {
      margin-top: 0.444rem;
    }

    & > h3 {
      font-size: 1.5rem;
      margin-top: 1rem;
      text-transform: none;
    }

    & .button {
      margin-top: 1rem;
    }

    & figure {
      display: flex;
      justify-content: center;
      margin: 1.5rem 0;
    }

    & img {
      height: auto;
      max-width: 500px;
      width: 100%;
    }
  }

  & .how_it_works-main-article-content-info {
    border: 3px solid #0c042e;
    border-radius: 14px;
    font-size: 0.85rem;
    font-style: italic;
    margin: 1rem 0;
    padding: 1.25rem;
  }

  & .how_it_works-main-article-content-levels {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem 0;

    @media (--small) {
      align-items: center;
      flex-direction: column;
      width: 100%;
    }
  }

  & .how_it_works-main-article-content-levels-container {
    display: inline-flex;
    justify-content: flex-start;

    @media (--small) {
      flex-direction: column;
    }
  }

  & .how_it_works-main-article-content-levels-level {
    align-items: center;
    background-color: #fff;
    border-radius: 14px;
    box-shadow: var(--shadow-2);
    display: flex;
    flex-direction: column;
    height: 156px;
    justify-content: center;
    margin: 0.75rem;
    padding: 1.25rem 1rem;
    position: relative;
    text-align: center;
    width: 156px;

    &::after {
      border-top: 0.75rem solid transparent;
      border-left: 0.75rem solid #0c042e;
      border-bottom: 0.75rem solid transparent;
      content: "";
      height: 0;
      left: calc(100% + 0.444rem);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 0;

      @media (--small) {
        border-bottom: none;
        border-left: 0.75rem solid transparent;
        border-right: 0.75rem solid transparent;
        border-top: 0.75rem solid #0c042e;
        left: 50%;
        top: calc(100% + 0.444rem);
        transform: translateX(-50%);
      }
    }

    & > * + * {
      margin-top: 1rem;
    }

    & > .reward_icon {
      height: 60px;
      width: 60px;

      &.level_zero {
        background-color: #930062;
      }

      & .reward_icon-icon {
        height: 40px;
        width: 40px;
      }
    }

    & > h4 {
      line-height: 1;
      text-transform: uppercase;
    }
  }

  /* Remove the chevron from the last level card */
  &
    .how_it_works-main-article-content-levels-container:last-of-type
    .how_it_works-main-article-content-levels-level:last-of-type::after {
    content: none;
  }

  /*
  Rotate the chevron to the bottom on the last level of the first row
  when levels wrap into two lines
  */
  &
    .how_it_works-main-article-content-levels-container:first-of-type
    .how_it_works-main-article-content-levels-level:last-of-type::after {
    @media (--medium) {
      border-bottom: none;
      border-left: 0.75rem solid transparent;
      border-right: 0.75rem solid transparent;
      border-top: 0.75rem solid #0c042e;
      left: 50%;
      top: calc(100% + 0.444rem);
      transform: translateX(-50%);
    }
  }

  /*
  Rotate all chevrons to the left on the last row when levels
  wrap into two lines
  */
  &
    .how_it_works-main-article-content-levels-container:last-of-type
    .how_it_works-main-article-content-levels-level::after {
    @media (--medium) {
      border-bottom: 0.75rem solid transparent;
      border-left: none;
      border-right: 0.75rem solid #0c042e;
      border-top: 0.75rem solid transparent;
    }
  }

  /*
  Change the order of levels from right to left to the last row of levels
  when they wrap into two lines.
  Fix also arrows presence.
  */
  &
    .how_it_works-main-article-content-levels-container:last-of-type
    .how_it_works-main-article-content-levels-level {
    &:nth-child(1) {
      @media (--medium) {
        order: 3;
      }

      &::after {
        @media (--medium) {
          content: none;
        }
      }
    }

    &:nth-child(2) {
      @media (--medium) {
        order: 2;
      }
    }

    &:nth-child(3)::after {
      @media (--medium) {
        content: "";
      }
    }
  }

  & .how_it_works-main-article-content-statuses {
    margin-top: 1rem;
  }

  & .how_it_works-main-article-content-statuses-status {
    align-items: flex-start;
    display: flex;
    margin-top: 0.563rem;

    & > p {
      padding-top: 0.422rem;
    }
  }

  & .how_it_works-main-article-content-statuses-status-icon {
    align-items: center;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    height: 2.369rem;
    margin-right: 0.75rem;
    position: relative;
    width: 2.369rem;

    &::after {
      border-radius: 50%;
      content: "";
      height: 1.777rem;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1.777rem;
    }

    &.sent {
      background: rgba(244, 132, 0, 0.2);

      &::after {
        background: rgba(244, 132, 0, 1);
      }
    }

    &.clicked {
      background: rgba(70, 133, 255, 0.2);

      &::after {
        background: rgba(70, 133, 255, 1);
      }
    }

    &.discount {
      background: rgba(0, 185, 43, 0.2);

      &::after {
        background: rgba(0, 185, 43, 1);
      }
    }

    &.no_discount {
      background: rgba(254, 0, 0, 0.2);

      &::after {
        background: rgba(254, 0, 0, 1);
      }
    }

    & svg {
      height: 1.25rem;
      position: relative;
      width: auto;
      z-index: 1;
    }
  }
}
