@custom-media --medium (width <= 960px);
@custom-media --small (width <= 768px);

@custom-media --benefits-large (width <= 1372px);
@custom-media --benefits-medium (width <= 1115px);
@custom-media --benefits-small (width <= 768px);
@custom-media --benefits-xsmall (width <= 610px);

.landing {
  background-color: #f2f2f2;
  min-height: 100vh;
  width: 100%;
}

.landing-splash {
  background: linear-gradient(to right, #003564 0%, #003564 25%, #049b72 100%);
  color: #fff;
  padding: calc(var(--header-height) + 5.063rem) 1rem 7.594rem;
  position: relative;
  width: 100%;
  z-index: 1;

  @media (--small) {
    padding: calc(var(--header-height) + 2.25rem) 1rem 5.063rem;
  }

  &::after {
    background: url("../../assets/images/auth-bg.svg") 100vw 0 / cover;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleX(-1);
    width: 100%;
    z-index: -1;
  }
}

.landing-splash-container {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 1080px;
  width: 100%;

  @media (--small) {
    flex-direction: column;
  }
}

.landing-splash-content {
  flex-grow: 1;
  margin-right: 5.063rem;
  max-width: 640px;

  @media (--small) {
    margin-right: 0;
  }
}

/* Show the mobile battery on small screens only */
.landing-splash-content .landing-splash-content-battery {
  display: none;

  @media (--small) {
    display: block;
  }
}

.anonymous .landing-splash-content {
  margin-top: 5.063rem;

  @media (--small) {
    margin-top: 0;
  }
}

.landing-splash-content-message {
  & h1 {
    font-size: 2.369rem;
    margin-top: 1rem;

    & > span {
      color: #f4bb00;
    }

    & > br {
      @media (--small) {
        display: none;
      }
    }
  }

  & > .button {
    margin-top: 1.333rem;
  }
}

.landing-splash-content-message-caption {
  margin-top: 1.5rem;

  & > p + p {
    margin-top: 0.667rem;
  }
}

.landing-splash-content-rewards {
  margin-top: 3.375rem;

  & .landing_rewards {
    margin-top: 1rem;
  }

  & > .button {
    margin-top: 1.5rem;
  }
}

.landing-splash-content-battery {
  flex-grow: 0;

  @media (--small) {
    margin-top: 2.25rem;
  }
}

/* Show the desktop battery on non-small screens only */
.landing-splash-content + .landing-splash-content-battery {
  @media (--small) {
    display: none;
  }
}

.landing-splash-content-battery-temp {
  background: #fff;
  height: 50vh;
  width: 100px;
}

.landing-info-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1280px;
  padding: 7.594rem 1rem;
  width: 100%;

  @media (--small) {
    padding: 3.375rem 1rem;
  }

  & > * + * {
    margin-top: 2.25rem;
  }
}

.landing-info-cards {
  align-items: flex-start;
  display: flex;

  @media (--small) {
    flex-direction: column;
  }

  & > * + * {
    margin-left: 2.25rem;

    @media (--medium) {
      margin-left: 1rem;
    }

    @media (--small) {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}

.landing-benefits {
  padding: 5.063rem 1rem 7.594rem;

  @media (--benefits-small) {
    padding: 5.063rem 1rem 5.063rem;
  }
}

.landing-benefits-container {
  margin: auto;
  max-width: 1080px;

  & > .button {
    margin-top: 1.5rem;
  }
}

.landing-benefits-container-list {
  background-color: #fff;
  border-radius: 25px;
  box-shadow: var(--shadow-2);
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  padding: 0.5rem;

  & > .reward_card {
    flex-shrink: 0;
    margin: .5rem;

    @media (--benefits-large) {
      min-width: 240px;
      max-width: none;
      width: calc(33% - 1rem);
    }

    @media (--benefits-medium) {
      width: calc(50% - 1rem);
    }

    @media (--benefits-small) {
      width: calc(50% - 1rem);
    }

    @media (--benefits-xsmall) {
      width: calc(100% - 1rem);
    }
  }
}
