.textarea {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 400px;
  position: relative;
  width: 100%;

  & .textarea-label {
    font-size: 0.667rem;
    font-weight: 600;
    padding: 0 1rem;
  }

  & .textarea-helper {
    color: #8ca4b5;
    font-size: 0.667rem;
    padding: 0 1rem;
  }

  & .textarea-input {
    appearance: none;
    background-color: #f9fbfc;
    color: #0c042e;
    display: block;
    border: 1px solid #8ca4b5;
    border-radius: 20px;
    font-size: 1rem;
    margin-top: 4px;
    min-width: 200px;
    outline: none;
    overflow: auto;
    padding: 0.667rem 1rem;
    resize: none;
    transition: border-color 0.1s ease;
    width: 100%;

    &[readonly] {
      border-color: #ededed;

      &:focus {
        border-color: #ededed;
      }
    }

    &::placeholder {
      color: #8ca4b5;
    }

    &:focus {
      border-color: #0c042e;
    }
  }

  & .textarea-validation {
    color: #fe0000;
    font-size: 0.667rem;
    padding: 2px 1rem 0;
    position: relative;

    &::before {
      content: "*";
    }
  }
}
