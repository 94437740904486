@custom-media --small (width < 768px);

.authentication {
  align-items: center;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  padding: 0 0.667rem;
  position: relative;
  width: 100%;
  z-index: 1;

  &::after {
    background: url("../../assets/images/auth-bg.svg") bottom left / cover;
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  /* Signup variant */
  &.signup {
    background: linear-gradient(
      to right,
      #003564 0%,
      #003564 25%,
      #049b72 100%
    );

    &::after {
      background-position: 100vw 0;
      transform: scaleX(-1);
    }
  }

  @media (--small) {
    background: #fff;
  }

  & .authentication-logo {
    height: 2.25rem;
    margin-top: 5.063rem;

    @media (--small) {
      margin-top: 1.5rem;
    }
  }

  & .contained_form {
    margin-top: 5.063rem;

    @media (--small) {
      margin-top: 2.25rem;
    }
  }

  & .authentication-footer {
    margin-top: auto;
    padding: 5.063rem 0 1rem;

    @media (--small) {
      padding: 3.375rem 0 1rem;
    }
  }

  /* Signup variant */
  &.signup .authentication-footer {
    color: #fff;
  }

  & .authentication-footer-list {
    display: flex;

    & li {
      text-decoration: underline;

      @media (--small) {
        font-size: 0.667rem;
      }

      & + li {
        margin-left: 2.25rem;

        @media (--small) {
          margin-left: 1.5rem;
        }
      }
    }
  }
}
