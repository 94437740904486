@custom-media --large (width > 1260px);
@custom-media --small (width <= 1260px);

.header_default {
  align-items: center;
  color: #fff;
  display: flex;
  font-weight: 600;
  height: var(--header-height);
  left: 0;
  justify-content: space-between;
  padding: 0 1.5rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: var(--z-header);

  @media (--small) {
    align-items: flex-start;
    padding: 0;
  }

  & .header-logo {
    cursor: pointer;
    height: 2.25rem;

    @media (--small) {
      position: absolute;
      left: 1.5rem;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1000;
    }
  }

  & .header-hamburger {
    display: none;

    @media (--small) {
      cursor: pointer;
      display: inline-block;
      height: 40px;
      position: absolute;
      right: calc(
        1.5rem - 0.35rem
      ); /* Position without considering the safe area */
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      z-index: 1000;
    }
  }

  & .header-hamburger-inner {
    background-color: #fff;
    height: 3px;
    left: 0.35rem;
    position: absolute;
    top: 1rem;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-duration: 75ms;
    width: 1.5rem;

    &.is_nav_shown {
      transform: rotate(45deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    /* Top and bottom lines */
    &::after,
    &::before {
      background-color: #fff;
      content: "";
      display: block;
      height: 3px;
      position: absolute;
      transition-timing-function: ease;
      transition-duration: 0.15s;
      transition-property: transform;
    }

    &::after {
      bottom: -0.5rem;
      transition: bottom 75ms ease 0.12s, opacity 75ms ease;
      width: 20px;
    }

    &.is_nav_shown {
      &::after {
        bottom: 0;
        transition: bottom 75ms ease, opacity 75ms ease 0.12s;
      }
    }

    &::before {
      top: -0.5rem;
      transition: top 75ms ease 0.12s,
        transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
      width: 1.5rem;
    }

    &.is_nav_shown {
      &::before {
        transition: top 75ms ease,
          transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
        transform: rotate(-90deg);
        top: 0;
      }
    }
  }

  /* Mobile drawer container */
  & .header-nav {
    @media (--small) {
      height: 100vh;
      overflow: hidden;
      position: relative;
      pointer-events: none;
      width: 100vw;
    }

    &.is_nav_shown {
      pointer-events: all;
    }
  }

  & .header-nav-list {
    align-items: center;
    display: flex;

    /* Mobile drawer link list */
    @media (--small) {
      align-items: center;
      background: linear-gradient(315deg, #0c042e 0%, #003564 100%);
      display: flex;
      flex-direction: column;
      height: 100%;
      left: 0;
      padding: calc(72px + 1.5rem) 1rem; /* Consider header height */
      position: absolute;
      top: 0;
      transform: translate3d(100%, 0, 0);
      transition: transform 0.2s ease;
      width: 100%;
    }

    &.is_nav_shown {
      transform: translate3d(0, 0, 0);
      transition-duration: 0.25s;
    }
  }

  & .header-nav-list-link {
    cursor: pointer;
    position: relative;
    text-transform: uppercase;
    transition: opacity 0.1s ease;

    @media (--small) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      opacity: 0.67;
    }
  }

  & .header-nav-list-link + .header-nav-list-link {
    margin-left: 1.5rem;

    @media (--small) {
      margin-left: 0;
      margin-top: 1.5rem;
    }
  }

  & .header-nav-list-link-tabula_roadmap {
    display: inline-block;

    & > img {
      height: 3rem;
    }
  }

  & .header-nav-list-link.header-nav-list-login_link {
    margin-left: 5.063rem;

    @media (--small) {
      font-size: 1rem;
      margin-left: 0;
      margin-top: 3rem;
      padding: 0 1rem;
      position: relative;
    }

    &::before {
      @media (--small) {
        background: #fff;
        bottom: calc(100% + 1.5rem);
        content: "";
        cursor: default;
        height: 3px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }

  & .header-nav-list-login_link a {
    align-items: center;
    display: flex;
  }

  & .header-nav-list-login_link svg {
    height: 14px;
    margin-left: 0.5rem;
    width: auto;
  }

  /* Header variant for info templates */
  &.header_info {
    background: #f7f7f7;
    color: #0c042e;
    box-shadow: rgba(50, 50, 93, 0.09) 0 2px 5px 0,
      rgba(0, 0, 0, 0.07) 0 1px 2px 0;

    & .header-nav-list-link-login_chevron path {
      fill: #0c042e;
    }

    & .header-hamburger-inner,
    & .header-hamburger-inner::after,
    & .header-hamburger-inner::before {
      background-color: #003564;
    }

    & .header-nav-list {
      @media (--small) {
        background: linear-gradient(315deg, #d9d9d9 0%, #f7f7f7 100%);
      }
    }

    & .header-nav-list-link.header-nav-list-login_link::before {
      background-color: #003564;
    }
  }
}
